import React from 'react'
import { AccordionDefaultHeaderElement } from './AccordionDefaultHeader.style'

const AccordionHeader: React.FC = ({ children, ...props }) => (
  <AccordionDefaultHeaderElement {...props}>
    {children}
  </AccordionDefaultHeaderElement>
)

export { AccordionHeader }
