import type { FoudationTokens } from '../../types'

const statusLabel = (tokens: FoudationTokens) => ({
  base: {
    bullet: {
      borderRadius: '100%',
    },
    text: {
      color: tokens.colors.neutral[90],
      fontFamily: tokens.fontFamily.base,
      spacingLeft: tokens.spacingLeft.sm,
    },
  },
  sizes: {
    small: {
      container: {
        spacingX: tokens.spacingX.sm,
        spacingY: tokens.spacingY.sm,
        spacingLeft: '22px',
      },
      bullet: {
        width: '12px',
        height: '12px',
      },
      text: {
        fontSize: tokens.fontSizes.xs,
      },
    },
    medium: {
      container: {
        spacingX: tokens.spacingX.md,
        spacingY: tokens.spacingY.md,
        spacingLeft: '28px',
      },
      bullet: {
        width: '16px',
        height: '16px',
      },
      text: {
        fontSize: tokens.fontSizes.md,
      },
    },
  },
  variants: {
    neutral: {
      bullet: {
        backgroundColor: tokens.colors.neutral[15],
      },
    },
    error: {
      bullet: {
        backgroundColor: tokens.colors.error[60],
      },
    },
    information: {
      bullet: {
        backgroundColor: tokens.colors.informational[60],
      },
    },
    success: {
      bullet: {
        backgroundColor: tokens.colors.primary[60],
      },
    },
    warning: {
      bullet: {
        backgroundColor: tokens.colors.warning[60],
      },
    },
  },
  disabled: {
    text: {
      color: tokens.colors.neutral[40],
    },
  },
})

export default statusLabel
