import type { FoudationTokens } from '../../types'

const select = (tokens: FoudationTokens) => ({
  box: {
    width: '245px',
    maxWidth: '100%',
    margin: ` ${tokens.spacingX['xs']} ${tokens.spacingY['sm']}`,
  },
  trigger: {
    height: '64px',
    borderRadius: tokens.radius['4xl'],
    padding: `0 ${tokens.spacingX['5xl']}`,
    color: tokens.colors.neutral[80],
    backgroundColor: tokens.colors.neutral[10],
    boxShadow: tokens.shadow.sm,
    transition: tokens.motion.sm,
    disabled: {
      color: tokens.colors.neutral[40],
    },
    focus: {
      backgroundColor: tokens.colors.neutral[0],
      boxShadow: tokens.shadow.md,
    },
  },
  triggerContent: {
    width: '100%',
  },
  content: {
    minWidth: '245px',
    width: '100%',
    top: '64px',
    left: '0',
    boxShadow: tokens.shadow.xs,
    borderRadius: tokens.radius['4xl'],
  },
  hint: {
    margin: `${tokens.spacingTop.md}} 0 0 0`,
  },
  item: {
    backgroundColor: tokens.colors.neutral[0],
    color: tokens.colors.neutral[80],
    padding: `${tokens.spacingY['5xl']} ${tokens.spacingY['4xl']}`,
    focus: {
      backgroundColor: tokens.colors.neutral[10],
    },
  },
})

export default select
