import React from 'react'
import { Transition } from 'react-transition-group'
import { BackdropElement, transitionDuration } from './Backdrop.style'
import type { BackdropProps } from './types'

const Backdrop = ({
  component = 'div',
  children,
  className,
  zIndex = 1,
  open = false,
  onClick,
  ...props
}: BackdropProps) => (
  <Transition
    unmountOnExit
    in={open}
    timeout={{ enter: 0, exit: transitionDuration }}
  >
    {state => (
      <BackdropElement
        zIndex={zIndex}
        as={component}
        className={className}
        {...props}
        transitionState={state}
        onClick={event => {
          if (event.target === event.currentTarget) {
            event.preventDefault()
            onClick?.(event)
          }
        }}
      >
        {children}
      </BackdropElement>
    )}
  </Transition>
)

export { Backdrop }
