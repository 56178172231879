/* eslint-disable react/jsx-no-constructed-context-values */
import * as React from 'react'
import { Backdrop } from '@creditas-ui/backdrop'

import * as Dialog from './shared'
import { Wrapper } from './ModalRoot.style'
import { ModalContext } from './context'

import type { ModalFlexProps } from './types'

export const ModalRoot = ({
  isOpen,
  closeModal,
  openModal,
  children,
  allowClose = true,
  size = 'medium',
  zIndex = 1,
  ...rest
}: ModalFlexProps) => (
  <ModalContext.Provider value={{ isOpen, closeModal, openModal, allowClose }}>
    <Dialog.Root
      modal
      open={isOpen}
      onOpenChange={() => allowClose && closeModal && closeModal()}
    >
      <Dialog.Portal>
        <Dialog.Overlay>
          <Backdrop
            open
            alignItems="center"
            justifyContent="center"
            zIndex={zIndex}
          >
            <Wrapper size={size} children={children} {...rest} />
          </Backdrop>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  </ModalContext.Provider>
)
