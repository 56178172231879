import * as React from 'react'
import { styled } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import type { StyledSystemProps } from '@creditas-ui/utilities'
import { ifProp, selectTheme, getTypography } from '@creditas-ui/utilities'

type InputHelperTextBaseProps = {
  /**
   * Optional change html element of component
   */
  component?: React.ElementType
  /**
   * Error label state
   */
  error?: boolean
  /**
   * Success label state
   */
  success?: boolean
  /**
   * Optional to add styles
   */
  className?: string
  /**
   * Optional children element of component
   */
  children?: React.ReactNode
}

type InputHelperTextElProps = React.HTMLAttributes<HTMLSpanElement>
type InputHelperTextProps = InputHelperTextBaseProps &
  InputHelperTextElProps &
  StyledSystemProps

const InputHelperTextElement = styled(
  Box.withComponent('span'),
)<InputHelperTextProps>`
  display: block;
  padding: ${selectTheme('input.helperText.padding')};
  ${getTypography('bodyXsRegular')}
  color: ${ifProp(
    'error',
    selectTheme('input.helperText.error.color'),
    ifProp(
      'success',
      selectTheme('input.helperText.success.color'),
      selectTheme('input.helperText.color'),
    ),
  )};
`

const InputHelperText: React.FC<InputHelperTextProps> = ({
  component = 'span',
  error,
  success,
  className,
  children,
  ...props
}) => (
  <InputHelperTextElement
    {...props}
    as={component}
    className={className}
    error={error}
    success={success}
  >
    {children}
  </InputHelperTextElement>
)

export { InputHelperText, InputHelperTextProps }
