import type { FoudationTokens } from '../../types'

const quote = (tokens: FoudationTokens) => ({
  base: {
    box: {
      width: '100%',
      minWidth: '285px',
      paddingLeft: tokens.spacingLeft['5xl'],
      borderLeftWidth: tokens.stroke.sm,
      borderLeftStyle: 'solid',
      fontSize: tokens.fontSizes.md,
      fontFamily: tokens.fontFamily.base,
    },
    title: {
      marginBottom: tokens.spacingBottom.md,
      fontWeight: tokens.fontWeight.lg,
      color: tokens.colors.neutral[90],
    },
    text: {
      fontWeight: tokens.fontWeight.xs,
      lineHeight: tokens.lineHeight.md,
      color: tokens.colors.neutral[80],
    },
  },
})

export default quote
