import React from 'react'
import type { IconsProps } from '../../types'
import { ColorPropsEnum } from '../../types'

export const IconArrow2 = ({
  size,
  strokeWidth = 1.5,
  color = ColorPropsEnum.black,
}: IconsProps) => (
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M8,10l4,4l4,-4"
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      stroke={color}
      fill="none"
      strokeLinejoin="round"
    />
    <path fill="none" d="M0,0h24v24h-24Z" />
  </svg>
)
