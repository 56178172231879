import React from 'react'

import { IconArrow2 } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'

import { Select as DefaultSelect } from './libs'

import {
  Box,
  Content,
  HintLabel,
  Label,
  Trigger,
  TriggerContent,
  TriggerInputContainer,
  Value,
  Viewport,
} from './Select.style'
import type { SelectProps } from './types'
import { useSelect } from './useSelect'
import Options from './Options'

export const Select = ({
  value,
  placeholder,
  options,
  onChange,
  hint,
  disabled,
  placeholderFeedback = 'normal',
  ...others
}: SelectProps) => {
  const { isShrink, hintColor, labelColor, handleSelectOnChange } = useSelect({
    value,
    hint,
    onChange,
    placeholderFeedback,
  })

  return (
    <Box>
      <DefaultSelect.Root
        {...others}
        value={value}
        onValueChange={handleSelectOnChange}
        disabled={disabled}
      >
        <Trigger
          data-disabled={disabled}
          data-testid="selectBtnTestId"
          data-placeholder={placeholder}
        >
          <TriggerContent>
            {placeholder && (
              <Label
                component="label"
                variant={isShrink ? 'bodyXsRegular' : 'bodyMdRegular'}
                color={disabled ? 'neutral.40' : labelColor}
                shrink={isShrink}
                data-placeholder-disabled={disabled}
              >
                {placeholder}
              </Label>
            )}
            <TriggerInputContainer>
              <Value data-value-disabled={disabled} />
            </TriggerInputContainer>
          </TriggerContent>
          <GlobalIcons Icon={IconArrow2} size="medium" />
        </Trigger>
        <DefaultSelect.Portal>
          <Content>
            <Viewport>
              <Options options={options} disabled={disabled} />
            </Viewport>
          </Content>
        </DefaultSelect.Portal>
      </DefaultSelect.Root>
      {hint && (
        <HintLabel component="span" variant="bodyXsRegular" color={hintColor}>
          {hint.message}
        </HintLabel>
      )}
    </Box>
  )
}
