import type { FoudationTokens } from '../../types'

const shimmer = (tokens: FoudationTokens) => ({
  base: {
    time: '3.0s',
  },
  variants: {
    light: {
      color: tokens.colors.neutral['20'],
    },
    dark: {
      color: tokens.colors.neutral['90'],
    },
  },
})

export default shimmer
