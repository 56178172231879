import * as React from 'react'
import { Flex } from '@creditas-ui/layout'
import { InputRadio } from './InputRadio'
import { RadioElements, InputLabel, InputHint } from './Radio.style'

type RadioBaseProps = {
  /**
   * Optional change html element of component
   */
  component?: React.ElementType
  /**
   * Optional input name
   */
  name?: string
  /**
   * Optional input unique id
   */
  id?: string
  /**
   * Optional input value
   */
  value?: string
  /**
   * Label of the radio component
   */
  label?: string
  /**
   * Hint (description) for the radio component
   */
  hint?: string
  /**
   * Checked attribute for the radio component
   */
  checked?: boolean
  /**
   * If true, the input will be disabled.
   */
  disabled?: boolean
  /**
   * Optional to add styles
   */
  className?: string
  /**
   * Optional onChange event
   */
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  /**
   * Optional onBlur event
   */
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void
  /**
   * The ref to the html dom element
   */
  ref?: React.Ref<HTMLInputElement>
}

type RadioElProps = React.InputHTMLAttributes<HTMLInputElement>
type RadioProps = RadioBaseProps & RadioElProps

const Radio = React.forwardRef<HTMLInputElement, RadioBaseProps>(
  (
    {
      component = 'input',
      name = '',
      id = '',
      value,
      label,
      hint,
      checked = false,
      disabled = false,
      className,
      onChange,
      onBlur,
      ...props
    },
    ref,
  ) => (
    <Flex>
      <InputRadio
        {...props}
        as={component}
        name={name}
        id={id}
        value={value}
        checked={checked}
        disabled={disabled}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
      <RadioElements>
        {label && (
          <InputLabel htmlFor={id} active={checked} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        {hint && <InputHint>{hint}</InputHint>}
      </RadioElements>
    </Flex>
  ),
)

export { Radio, RadioProps }
