import type { TypographyColors } from '@creditas-ui/typography'
import { useEffect, useState } from 'react'
import type { SelectOption, Hint, PlaceholderFeedback } from './types'

interface useSelectProps {
  onChange: (value: SelectOption['label']) => void
  value?: string | undefined
  hint?: Hint
  placeholderFeedback?: PlaceholderFeedback
}

const useSelect = ({
  onChange,
  value,
  hint,
  placeholderFeedback,
}: useSelectProps) => {
  const [isShrink, setIsShrink] = useState(false)

  const labelColors: { [key: string]: TypographyColors } = {
    normal: 'neutral.80',
    success: 'primary.60',
    error: 'error.60',
  }

  const hintColor = hint && labelColors[hint?.type]
  const labelColor = placeholderFeedback
    ? labelColors[placeholderFeedback]
    : labelColors.normal

  function handleSelectOnChange(value: SelectOption['label']) {
    if (value === undefined || value.trim() === '') setIsShrink(false)
    setIsShrink(true)
    onChange(value)
  }

  function hasToStartShrink() {
    return value !== undefined
  }

  useEffect(() => {
    if (hasToStartShrink()) {
      setIsShrink(true)
    }
  }, [value])

  return {
    isShrink,
    handleSelectOnChange,
    labelColor,
    hintColor,
    hasToStartShrink,
  }
}

export { useSelect }
