import compose from 'ramda/src/compose'
import split from 'ramda/src/split'
import head from 'ramda/src/head'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'
import slice from 'ramda/src/slice'
import is from 'ramda/src/is'
import join from 'ramda/src/join'
import call from 'ramda/src/call'
import ifElse from 'ramda/src/ifElse'
import partialRight from 'ramda/src/partialRight'
import type { Theme } from '@emotion/react'

const splitByDot: (str: string) => string[] = split('.')

const firstByDot = compose<string, string[], any>(head, splitByDot)

const componentTokenPath = compose(join('.'), slice(1, Infinity), splitByDot)

const themePropSelector: any = (theme: any) => partialRight(prop, [theme])

const themePathSelector = (theme: any) => partialRight(path, [theme])

const predicate = (theme: any) =>
  compose(is(Function), themePropSelector(theme), firstByDot)

const componentTokenSelector = (theme: any) => (path: string) =>
  compose(
    partialRight(call, [componentTokenPath(path)]),
    themePropSelector(theme),
    firstByDot,
  )(path)

const themeTokenSelector = (theme: any) =>
  compose(themePathSelector(theme), splitByDot)

type selectThemeArg = {
  theme: Theme
}

const selectTheme =
  (path: string) =>
  ({ theme }: selectThemeArg) =>
    ifElse(
      predicate(theme),
      componentTokenSelector(theme),
      themeTokenSelector(theme),
    )(path)

export { selectTheme }
