import * as React from 'react'
import { useProgress } from '../../useProgress'
import { propsSpinner } from './constants'
import {
  ContainerElement,
  BaseCircle,
  ProgressbarCircle,
} from './ProgressCircle.style'
import type { SpinnerIndeterminateProps } from '../../types'

export const ProgressCircle = ({
  progress = 0,
  size = 'small',
}: SpinnerIndeterminateProps) => {
  const { handleStrokeDasharray } = useProgress()

  return (
    <ContainerElement size={size} role="progressbar">
      <BaseCircle {...propsSpinner[size]} />
      <ProgressbarCircle
        {...propsSpinner[size]}
        strokeDashoffset={handleStrokeDasharray(
          progress,
          propsSpinner[size].strokeDasharray,
        )}
      />
    </ContainerElement>
  )
}
