import * as React from 'react'

import { SpinnerIndeterminateElement } from './Spinner.style'
import type { SpinnerIndeterminateProps } from '../../types'

/**
 * Primary UI component for user interaction
 */
export const Spinner: React.FC<SpinnerIndeterminateProps> = ({
  accessibilityMessage,
  size = 'small',
  ...props
}: SpinnerIndeterminateProps) => (
  <SpinnerIndeterminateElement {...props} size={size}>
    <span>{accessibilityMessage}</span>
  </SpinnerIndeterminateElement>
)
