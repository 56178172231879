import React from 'react'

import { Wrapper, Container } from './ModalFooter.style'

import type { FooterElementProps } from '../types'
import { FooterSelector } from './variants'

export const ModalFooter = ({
  children,
  hideFooter,
  primaryLabel,
  secondaryLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  variant,
  ...rest
}: FooterElementProps) => (
  <Container {...rest}>
    {children ||
      (!hideFooter && (
        <Wrapper>
          <FooterSelector
            variant={variant}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            onPrimaryButtonClick={onPrimaryButtonClick}
            onSecondaryButtonClick={onSecondaryButtonClick}
          />
        </Wrapper>
      ))}
  </Container>
)
