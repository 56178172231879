import type { FoudationTokens } from '../../types'

const typography = (tokens: FoudationTokens) => ({
  base: {
    fontFamily: tokens.fontFamily.base,
    fontWeight: {
      light: tokens.fontWeight.xs,
      regular: tokens.fontWeight.sm,
      medium: tokens.fontWeight.md,
      bold: tokens.fontWeight.lg,
    },
  },
  heading: {
    '5xl': {
      fontSize: tokens.fontSizes['10xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['8xl'],
    },
    '4xl': {
      fontSize: tokens.fontSizes['9xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['8xl'],
    },
    '3xl': {
      fontSize: tokens.fontSizes['8xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['6xl'],
    },
    '2xl': {
      fontSize: tokens.fontSizes['7xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['6xl'],
    },
    xl: {
      fontSize: tokens.fontSizes['6xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['5xl'],
    },
    lg: {
      fontSize: tokens.fontSizes['5xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['4xl'],
    },
    md: {
      fontSize: tokens.fontSizes['4xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['3xl'],
    },
    sm: {
      fontSize: tokens.fontSizes['3xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight['2xl'],
    },
    xs: {
      fontSize: tokens.fontSizes['2xl'],
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight.xl,
    },
  },
  body: {
    xl: {
      fontSize: tokens.fontSizes.xl,
      letterSpacing: tokens.letterSpacing.sm,
      lineHeight: tokens.lineHeight.xl,
    },
    lg: {
      fontSize: tokens.fontSizes.lg,
      letterSpacing: tokens.letterSpacing.xs,
      lineHeight: tokens.lineHeight.lg,
    },
    md: {
      fontSize: tokens.fontSizes.md,
      letterSpacing: tokens.letterSpacing.xs,
      lineHeight: tokens.lineHeight.md,
    },
    sm: {
      fontSize: tokens.fontSizes.sm,
      letterSpacing: tokens.letterSpacing.xs,
      lineHeight: tokens.lineHeight.sm,
    },
    xs: {
      fontSize: tokens.fontSizes.xs,
      letterSpacing: tokens.letterSpacing.xs,
      lineHeight: tokens.lineHeight.xs,
    },
  },
  exception: {
    md: {
      fontSize: tokens.fontSizes.sm,
      fontWeight: tokens.fontWeight.lg,
      letterSpacing: tokens.letterSpacing.md,
      lineHeight: tokens.lineHeight.sm,
    },
    sm: {
      fontSize: tokens.fontSizes.xs,
      fontWeight: tokens.fontWeight.lg,
      letterSpacing: tokens.letterSpacing.md,
      lineHeight: tokens.lineHeight.xs,
    },
  },
})

export default typography
