import type { TypographyVariants } from './types'

const getTypeScale = (variant: TypographyVariants) => {
  const TYPE_SCALE_PATTERN = new RegExp(
    '(?<type>heading|body|exception)(?<size>[0-9]?[a-z]{2})(?<weight>.*)',
    'i',
  )
  const typsScaleMatch = variant.toLowerCase().match(TYPE_SCALE_PATTERN)

  return typsScaleMatch?.groups || {}
}

const getTypography =
  (variant: TypographyVariants) =>
  ({ theme }: any) => {
    if (typeof variant !== 'string' || !variant || !theme.typography) return ''

    const { typography } = theme
    const { type, size, weight } = getTypeScale(variant)
    const token = `${type}.${size}`
    const { fontFamily, fontWeight } = typography('base') || {}
    const { fontSize, letterSpacing, lineHeight } = typography(token) || {}
    const hasCompletelyTypeScale =
      fontFamily &&
      fontSize &&
      fontWeight[weight] &&
      letterSpacing &&
      lineHeight

    return hasCompletelyTypeScale
      ? `
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight[weight]};
      letter-spacing: ${letterSpacing}px;
      line-height: ${lineHeight};
    `
      : ''
  }

export { getTypography }
