import * as React from 'react'
import { styled } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import type { StyledSystemProps } from '@creditas-ui/utilities'

type InputBaseProps<T> = {
  /**
   * Optional input type element of component
   */
  type?: string
  /**
   * The component used for the input element. It should be a component or a string (for HTML elements).
   */
  component?: React.ElementType
  /**
   * Input autoFocus attribute
   */
  autoFocus?: boolean
  /**
   * Input autoComplete attribute
   */
  autoComplete?: string
  /**
   * If true, the `Input` will be disabled.
   */
  disabled?: boolean
  /**
   * Optional input name
   */
  name?: string
  /**
   * Optional input unique id
   */
  id?: string
  /**
   * Optional input value
   */
  value?: string
  /**
   * Optional input placeholder
   */
  placeholder?: string
  /**
   * Apply input error state if it has an error.
   */
  error?: string
  /**
   * Optional input helper text element
   */
  helperText?: string
  /**
   * Optional input success text element
   */
  success?: string
  /**
   * Optional to add styles
   */
  className?: string
  /**
   * Optional onChange event
   */
  onChange?: (event: React.FormEvent<T>) => void
  /**
   * Optional onFocus event
   */
  onFocus?: (event: React.FormEvent<T>) => void
  /**
   * Optional onBlur event
   */
  onBlur?: (event: React.FormEvent<T>) => void
  /**
   * The ref to the html dom element
   */
  ref?: React.Ref<HTMLInputElement>
}

type InputElProps = React.InputHTMLAttributes<HTMLInputElement>
type InputProps = InputBaseProps<HTMLInputElement> &
  StyledSystemProps &
  InputElProps

const InputElement = styled(Box.withComponent('input'))<InputProps>`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
`

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      autoFocus = false,
      autoComplete = 'off',
      component = 'input',
      disabled = false,
      name,
      id,
      value,
      placeholder,
      className,
      onChange,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => (
    <InputElement
      {...props}
      ref={ref}
      type={type}
      as={component}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      disabled={disabled}
      name={name}
      id={id}
      value={value}
      placeholder={placeholder}
      className={className}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
    />
  ),
)

export { Input, InputProps }
