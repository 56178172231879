import { standard } from '@creditas-ui/themes'
import type { MediaSizes } from './media-helper'
import { mediaHelper } from './media-helper'

const numericBreakpoints = standard.breakpoints.map((breakpoint: string) =>
  parseInt(breakpoint.replace('px', ''), 10),
)

const [
  breakpointsXs,
  breakpointsSm,
  breakpointsMd,
  breakpointsLg,
  breakpointsXl,
  breakpoints2Xl,
  breakpoints3Xl,
  breakpoints4Xl,
  breakpoints5Xl,
  breakpoints6Xl,
  breakpoints7Xl,
  breakpoints8Xl,
  breakpoints9Xl,
] = numericBreakpoints

const sizes: MediaSizes = {
  xs: breakpointsXs,
  sm: breakpointsSm,
  md: breakpointsMd,
  lg: breakpointsLg,
  xl: breakpointsXl,
  '2xl': breakpoints2Xl,
  '3xl': breakpoints3Xl,
  '4xl': breakpoints4Xl,
  '5xl': breakpoints5Xl,
  '6xl': breakpoints6Xl,
  '7xl': breakpoints7Xl,
  '8xl': breakpoints8Xl,
  '9xl': breakpoints9Xl,
}

const media = mediaHelper(sizes)

export { media }
