import type { FoudationTokens } from '../../types'

const checkbox = (tokens: FoudationTokens) => ({
  base: {
    fontFamily: tokens.fontFamily.base,
  },
  container: {
    margin: `0 0 0 ${tokens.spacingLeft['3xl']}`,
  },
  shape: {
    width: '32px',
    height: '32px',
    background: 'transparent',
    radius: tokens.radius.lg,
    borderWidth: tokens.stroke.xs,
    borderColor: tokens.colors.neutral[60],
    outter: {
      width: '38px',
      height: '38px',
    },
    ripple: {
      background: tokens.colors.primary[40],
      opacity: tokens.opacity.sm,
      transition: tokens.motion.xs,
      active: {
        opacity: tokens.opacity.sm,
      },
    },
    active: {
      background: tokens.colors.primary[40],
      borderColor: tokens.colors.primary[40],
    },
    disabled: {
      borderColor: tokens.colors.neutral[40],
      opacity: tokens.opacity.md,
      active: {
        opacity: tokens.opacity.xl,
      },
    },
    error: {
      borderColor: tokens.colors.error[60],
    },
  },
  label: {
    fontSize: tokens.fontSizes.md,
    fontWeight: tokens.fontWeight.sm,
    lineHeight: tokens.lineHeight.md,
    letterSpacing: tokens.letterSpacing.xs,
    color: tokens.colors.neutral[80],
    marginLeft: tokens.spacingLeft['3xl'],
    active: {
      color: tokens.colors.neutral[90],
    },
    disabled: {
      color: tokens.colors.neutral[40],
    },
  },
  hint: {
    fontSize: tokens.fontSizes.sm,
    fontWeight: tokens.fontWeight.sm,
    lineHeight: tokens.lineHeight.sm,
    letterSpacing: tokens.letterSpacing.xs,
    color: tokens.colors.neutral[60],
    marginTop: tokens.spacingTop.sm,
  },
  group: {
    marginBetweenItems: tokens.spacingTop['3xl'],
    marginLeft: tokens.spacingLeft['3xl'],
  },
})

export default checkbox
