import type { FoudationTokens } from '../../types'

const backdrop = (tokens: FoudationTokens) => ({
  container: {
    backgroundColor: tokens.colors.neutral[90],
    opacity: tokens.opacity.xl,
  },
})

export default backdrop
