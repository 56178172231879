import { css } from '@creditas-ui/system'

export const scrollBar = ({ theme: { dropdown } }: any) => css`
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${dropdown('options.scrollbar.backgroundColor')};
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }
`
