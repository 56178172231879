import { Flex } from '@creditas-ui/layout'
import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'
import type { BackdropProps, TransitionStatus } from './types'

const transitionDuration = 300

const onOpenTransition = ({ transitionState, theme }) =>
  transitionState === 'entered' &&
  `opacity: ${(theme as any).backdrop('container.opacity')};}`
const onCloseTransition = ({ transitionState }) =>
  transitionState === 'exiting' && 'opacity: 0;'

const BackdropElement = styled(Flex)<
  BackdropProps & { transitionState: TransitionStatus }
>`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: opacity ${transitionDuration}ms ease-in-out;
  opacity: 1;
  ${onCloseTransition}
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${selectTheme('backdrop.container.backgroundColor')};
    transition: opacity ${transitionDuration}ms ease-in-out;
    opacity: 0;
    z-index: -1;
    ${onOpenTransition}
  }
`
export { BackdropElement, transitionDuration }
