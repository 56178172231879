import type { StyledSystemProps } from '@creditas-ui/utilities'

type EventCallback = (ev: any) => void

export type SizePropsType = 'x_small' | 'small' | 'medium' | 'large'

export enum ColorPropsEnum {
  black = '#000000',
}

interface IconsBaseProps {
  className?: string
  size?: number
  strokeWidth?: number
  color?: string
  component?: React.ElementType
  onClick?: EventCallback
}

type IconsElProps = Omit<React.HTMLAttributes<HTMLElement>, 'size'>
type IconsStyledProps = Omit<StyledSystemProps, 'size' | 'color'>

export type IconsProps = IconsBaseProps & IconsElProps & IconsStyledProps
