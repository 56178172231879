import pick from 'ramda/src/pick'

export const pickIconsThemePropsHelper = (
  object: Record<string, unknown>,
  omitKeys: string[],
): Record<string, any> =>
  Object.keys(object)
    .map(key => ({
      [key]: pick(omitKeys, object[key]),
    }))
    .reduce((previous, current) => ({ ...previous, ...current }), {})
