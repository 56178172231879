const useProgress = () => {
  const handleProgress = (progress: number) =>
    Math.max(0, Math.min(progress, 100))

  const handleStrokeDasharray = (progress: number, strokeDasharray: number) =>
    strokeDasharray - (handleProgress(progress) * strokeDasharray) / 100

  return { handleProgress, handleStrokeDasharray }
}

export { useProgress }
