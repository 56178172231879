import type { FoudationTokens } from '../../types'

const tooltip = (tokens: FoudationTokens) => ({
  base: {
    paddingY: tokens.spacingY.sm,
    paddingX: tokens.spacingX.md,
    shadow: tokens.shadow.xs,
    borderRadius: tokens.radius.sm,
    fontFamily: tokens.fontFamily.base,
    marginTop: tokens.spacingTop.md,
    marginBottom: tokens.spacingBottom.md,
    marginRight: tokens.spacingRight.md,
    marginLeft: tokens.spacingLeft.md,
  },
  dark: {
    color: tokens.colors.neutral[0],
    backgroundColor: tokens.colors.neutral[90],
  },
  light: {
    color: tokens.colors.neutral[90],
    backgroundColor: tokens.colors.neutral[0],
  },
})

export default tooltip
