import type { FoudationTokens } from '../../types'

const tag = (tokens: FoudationTokens) => ({
  base: {
    container: {
      paddingX: tokens.spacingX.xl,
      borderRadius: tokens.radius['4xl'],
      shadow: tokens.shadow.xs,
    },

    text: {
      color: tokens.colors.neutral[80],
    },

    icons: {
      base: {
        opacity: tokens.opacity.xl,
      },

      icon: {
        color: tokens.colors.neutral[80],
        sizeContainer: {
          small: {
            marginRight: tokens.spacingX['lg'],
          },
          medium: {
            marginRight: tokens.spacingX['xl'],
          },
        },
      },

      closeIcon: {
        marginLeft: tokens.spacingLeft['xl'],
        color: tokens.colors.neutral[90],
      },
    },
  },

  size: {
    small: {
      container: {
        paddingY: tokens.spacingY.sm,
      },
    },
    medium: {
      container: {
        paddingY: tokens.spacingY.md,
      },
    },
  },

  color: {
    primary: {
      backgroundColor: tokens.colors.neutral[10],
    },

    secondary: {
      backgroundColor: tokens.colors.neutral[0],
    },
  },

  disabled: {
    icon: {
      color: tokens.colors.neutral[40],
    },

    text: {
      color: tokens.colors.neutral[40],
    },
  },
})

export default tag
