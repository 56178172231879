import * as React from 'react'
import { ColorPropsEnum } from './types'
import { GlobalIconElement } from './GlobalIcons.styles'
import { globalIconsProps } from './constants'

import type { GlobalIconsElementProps } from './types'

export const GlobalIcons = ({
  Icon,
  size = 'large',
  color = ColorPropsEnum.black,
  ...props
}: GlobalIconsElementProps) => (
  <GlobalIconElement size={size} {...props}>
    <Icon color={color} {...globalIconsProps[size]} {...props} />
  </GlobalIconElement>
)
