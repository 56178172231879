import * as React from 'react'

export interface CreateContextOptions {
  errorMessage?: string
  name?: string
}

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>]

export function createContext<ContextType>({
  errorMessage,
  name,
}: CreateContextOptions = {}) {
  const Context = React.createContext<ContextType | undefined>(undefined)

  Context.displayName = name

  function useContext() {
    const context = React.useContext(Context)

    if (!context) {
      const defaultErrorMessage = `
        useContext: '${name || 'context'}' is undefined. 
        Did you forget to wrap your component within the provider?`
      const error = new Error(errorMessage || defaultErrorMessage)
      error.name = 'ContextError'
      throw error
    }

    return context
  }

  return [
    Context.Provider,
    useContext,
    Context,
  ] as CreateContextReturn<ContextType>
}
