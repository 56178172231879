import React from 'react'

import { Wrapper, Container, Content, ResetStyle } from './ModalBody.style'

import type { BodyElementProps } from '../types'

export const ModalBody = ({
  children,
  resetStyle,
  ...rest
}: BodyElementProps) =>
  resetStyle ? (
    <ResetStyle {...rest} children={children} />
  ) : (
    <Container>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  )
