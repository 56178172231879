import { styled } from '@creditas-ui/system'
import { selectTheme, mixins } from '@creditas-ui/utilities'
import { ifProp } from 'styled-tools'
import {
  Input,
  InputBoxedElement,
  InputLabel,
} from '../InputBoxed/InputBoxed.style'
import { InputHelperText } from '../InputHelperText'

type TextAreaBoxedState = {
  height?: string
  counter?: number
  focused: boolean
}

type LabelState = {
  active: boolean
  disabled: boolean
}

const TextAreaBoxedElement = styled(InputBoxedElement)<TextAreaBoxedState>`
  height: ${props => props.height || selectTheme('textarea.shape.height')};
`

const TextAreaLabel = styled(InputLabel)<LabelState>`
  top: ${ifProp(
    'active',
    selectTheme('textarea.placeholder.active.top'),
    selectTheme('textarea.placeholder.top'),
  )};
`

const TextAreaCounterText = styled(InputHelperText)`
  flex: 1;
  text-align: end;
  margin-left: ${selectTheme('textarea.counter.marginLeft')};
`

const TextAreaInput = styled(Input)`
  height: ${selectTheme('textarea.value.height')};
  ${mixins.scrollBar};
`

export {
  TextAreaBoxedElement,
  TextAreaLabel,
  TextAreaCounterText,
  TextAreaInput,
}
