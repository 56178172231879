import { styled } from '@creditas-ui/system'
import { media, getTypography } from '@creditas-ui/utilities'
import { AccordionBaseHeader } from '../../Base/BaseHeader'

const AccordionDefaultHeaderElement = styled(AccordionBaseHeader)`
  ${media.between('xs', '2xl')} {
    ${getTypography('bodyLgRegular')}
  }

  ${media.up('2xl')} {
    ${getTypography('headingSmRegular')}
  }
`

export { AccordionDefaultHeaderElement }
