import { styled, css } from '@creditas-ui/system'
import { mixins, selectTheme } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'

const maxHeight = '50vh'

const scrollBar = css`
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar-thumb {
    height: 68px;
    display: none;
    transition-delay: 250ms;
  }

  :hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
`

const baseContentEffect = css`
  height: 40px;

  width: 100%;

  flex-direction: column;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none;

  background: linear-gradient(
    180deg,
    #ffffffd6 0%,
    #ffffff16 35%,
    #ffffff10 70%
  );
`

const topContentEffect = css`
  ${baseContentEffect}
  top: 40px;
`

const bottomContentEffect = css`
  ${baseContentEffect}
  bottom: 20px;
  transform: rotate(180deg);
`

export const Container = styled(Box)`
  max-height: ${maxHeight};
  position: relative;

  padding-top: ${selectTheme('modal.content.paddingTop')};
  padding-bottom: ${selectTheme('modal.content.paddingBottom')};

  :hover {
    ::before {
      content: '';
      ${topContentEffect}
    }
    ::after {
      content: '';
      ${bottomContentEffect}
    }
  }
`

export const Wrapper = styled(Box)`
  max-height: ${maxHeight};
  padding: 0 ${selectTheme('modal.base.paddingX')};

  ${mixins.scrollBar};
  ${scrollBar};
`

export const Content = styled(Box)`
  margin-right: ${selectTheme('modal.content.paddingTop')};

  display: inline-flex;
  flex-direction: column;

  box-sizing: border-box;
`

export const ResetStyle = styled(Box)``
