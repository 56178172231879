import { BreakpointNotFoundError, NextBreakpointNotFoundError } from './errors'
import type { SizeEnum, MediaSizes } from './types'

class Media {
  constructor(private sizes: MediaSizes) {
    this.sizes = sizes
  }

  private isBreakpoint(breakpoint: SizeEnum) {
    return Boolean(this.sizes[breakpoint])
  }

  private next(breakpoint: SizeEnum) {
    const keys = Object.keys(this.sizes) as Array<SizeEnum>
    const index = keys.indexOf(breakpoint)

    return keys[index + 1]
  }

  public up(breakpoint: SizeEnum) {
    if (!this.isBreakpoint(breakpoint)) {
      throw new BreakpointNotFoundError(breakpoint)
    }

    return `@media (min-width: ${this.sizes[breakpoint]}px)`
  }

  // The max-width value is calculated as the next breakpoint less 0.02px.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug
  // in Safari. See https://bugs.webkit.org/show_bug.cgi?id=178261
  public down(breakpoint: SizeEnum) {
    if (!this.isBreakpoint(breakpoint)) {
      throw new BreakpointNotFoundError(breakpoint)
    }

    const nextBreakpoint = this.next(breakpoint)

    if (!this.isBreakpoint(nextBreakpoint)) {
      throw new NextBreakpointNotFoundError(breakpoint)
    }

    return `@media (max-width: ${this.sizes[nextBreakpoint] - 0.02}px)`
  }

  public between(min: SizeEnum, max: SizeEnum) {
    if (!this.isBreakpoint(min)) {
      throw new BreakpointNotFoundError(min)
    }

    if (!this.isBreakpoint(max)) {
      throw new BreakpointNotFoundError(max)
    }

    return `@media (min-width: ${this.sizes[min]}px) and (max-width: ${
      this.sizes[max] - 0.02
    }px)`
  }

  public only(breakpoint: SizeEnum) {
    const nextBreakpoint = this.next(breakpoint)

    return this.isBreakpoint(nextBreakpoint)
      ? this.between(breakpoint, nextBreakpoint)
      : this.up(breakpoint)
  }
}

const mediaHelper = (sizes: MediaSizes) => new Media(sizes)

export { Media, mediaHelper }
