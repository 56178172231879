import { styled } from '@creditas-ui/system'
import { Box, Flex } from '@creditas-ui/layout'
import { getTypography, ifProp, selectTheme } from '@creditas-ui/utilities'
import type { LabelProps } from '../InputLabel'
import { InputLabel } from '../InputLabel'
import type { InputProps } from '../Input'
import { Input } from '../Input'

type InputBoxedState = {
  focused: boolean
  hasIcon?: boolean
}

type LabelState = {
  active: boolean
  disabled: boolean
}

const InputBoxedElement = styled(Box)<InputBoxedState>`
  height: 64px;
  position: relative;
  box-sizing: border-box;
  padding: ${selectTheme('input.shape.padding')};
  border-radius: ${selectTheme('input.shape.borderRadius')};
  background-color: ${ifProp(
    'focused',
    selectTheme('input.shape.focus.backgroundColor'),
    selectTheme('input.shape.backgroundColor'),
  )};
  transition: background-color ${selectTheme('input.shape.transition')};
  z-index: 0;
  padding-right: ${ifProp(
    'hasIcon',
    selectTheme('input.icon.paddingRight'),
    undefined,
  )};

  &::before {
    content: ' ';
    position: absolute;
    opacity: ${ifProp('focused', 1, 0)};
    border-radius: ${selectTheme('input.shape.borderRadius')};
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: ${selectTheme('input.shape.boxShadow')};
    transition: opacity ${selectTheme('input.shape.transition')};
    pointer-events: none;
  }
`

const StyledLabel = styled(InputLabel)<LabelProps & LabelState>`
  position: absolute;
  top: ${ifProp('active', selectTheme('input.placeholder.active.top'), '30%')};
  transition: top ${selectTheme('input.placeholder.transition')} linear,
    font-size ${selectTheme('input.placeholder.transition')} linear;
  ${ifProp('active', getTypography('bodyXsRegular'))}
`

const StyledInput = styled(Input)<InputProps>`
  ${getTypography('bodyMdRegular')};
  background-color: rgba(255, 255, 255, 0);
  color: ${ifProp(
    'disabled',
    selectTheme('input.shape.disabled.color'),
    selectTheme('input.shape.color'),
  )};
  z-index: 1;
  resize: none;
  margin-left: -0.3px;
  margin-top: ${selectTheme('input.value.marginTop')};
  width: 100%;
`

const IconWrapper = styled(Flex)<{ disabled: boolean | undefined }>`
  z-index: 2;
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  margin-left: ${selectTheme('input.icon.marginLeft')};
  svg [stroke] {
    stroke: ${ifProp(
      'disabled',
      selectTheme('input.shape.disabled.color'),
      selectTheme('input.shape.color'),
    )};
  }
`

export {
  InputBoxedElement,
  StyledLabel as InputLabel,
  StyledInput as Input,
  IconWrapper,
}
