import type { FoudationTokens } from '../../types'

const inlineLink = (tokens: FoudationTokens) => ({
  base: {
    color: tokens.colors.neutral['90'],
    disabledColor: tokens.colors.neutral['40'],
    hover: {
      opacity: tokens.opacity['2xl'],
    },
  },
})

export default inlineLink
