import { Box } from '@creditas-ui/layout'
import { selectTheme } from '@creditas-ui/utilities'
import { styled } from '@creditas-ui/system'

const InputHint = styled(Box.withComponent('span'))`
  display: inline-block;
  margin: ${selectTheme('radio.hint.margin')};
  color: ${selectTheme('radio.hint.color')};
  font-size: ${selectTheme('radio.hint.fontSize')};
  font-weight: ${selectTheme('radio.hint.fontWeight')};
  line-height: ${selectTheme('radio.hint.lineHeight')};
  letter-spacing: ${selectTheme('radio.hint.letterSpacing')}px;
`

export { InputHint }
