import { styled, css } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import { ifProp, selectTheme, media } from '@creditas-ui/utilities'
import type { AccordionItemContainerProps } from '../types'

export const disabled = ({ theme: { accordion } }: any) => css`
  color: ${accordion('base.disabledFontColor')};

  div {
    cursor: not-allowed;
  }

  svg [stroke] {
    stroke: ${accordion('base.disabledFontColor')};
  }
`

const AccordionItemElement = styled(Box)<AccordionItemContainerProps>`
  border-bottom: ${selectTheme('accordion.base.borderBottom')} solid;
  border-bottom-color: ${selectTheme('accordion.base.borderColor')};

  ${media.between('xs', '2xl')} {
    padding-bottom: ${selectTheme('accordion.body.medium.paddingBottom')};
  }

  ${media.up('2xl')} {
    padding-bottom: ${selectTheme('accordion.body.large.paddingBottom')};
  }

  ${ifProp({ disabled: true }, disabled)};
`

export { AccordionItemElement }
