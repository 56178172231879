import { styled } from '@creditas-ui/system'
import { ifProp, selectTheme } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'

type RadioState = {
  checked: boolean
  disabled: boolean
}

const InputRadio = styled(Box.withComponent('input'))<RadioState>`
  appearance: none;
  position: relative;
  z-index: 1;
  margin: 0;
  flex-shrink: 0;
  overflow: hidden;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  width: ${selectTheme('radio.shape.width')};
  height: ${selectTheme('radio.shape.height')};
  opacity: ${ifProp('disabled', selectTheme('radio.shape.disabled.opacity'))};
  border-radius: ${selectTheme('radio.shape.radius')};
  border-style: solid;
  border-width: ${selectTheme('radio.shape.borderWidth')};
  border-color: ${ifProp(
    { disabled: true, checked: false },
    selectTheme('radio.shape.disabled.borderColor'),
    ifProp(
      'checked',
      selectTheme('radio.shape.active.borderColor'),
      selectTheme('radio.shape.borderColor'),
    ),
  )};
  background-color: ${ifProp(
    'checked',
    selectTheme('radio.shape.active.background'),
    selectTheme('radio.shape.background'),
  )};

  &:focus {
    outline: none;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      width: ${selectTheme('radio.shape.outter.width')};
      height: ${selectTheme('radio.shape.outter.height')};
      opacity: ${ifProp(
        'checked',
        selectTheme('radio.shape.ripple.active.opacity'),
        selectTheme('radio.shape.ripple.opacity'),
      )};
      transform: scale(1);
      background-color: ${selectTheme('radio.shape.ripple.background')};
      border-radius: ${selectTheme('radio.shape.radius')};
      animation: ripple ${selectTheme('radio.shape.ripple.transition')} linear;
      overflow: hidden;
    }
  }

  @keyframes ripple {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1.5);
    }
  }
`

InputRadio.defaultProps = {
  type: 'radio',
}

export { InputRadio }
