import * as React from 'react'
import { styled } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import type { StyledSystemProps } from '@creditas-ui/utilities'
import { ifProp, selectTheme, getTypography } from '@creditas-ui/utilities'

type LabelBaseProps = {
  /**
   * Optional htmlFor attribute
   */
  htmlFor?: string
  /**
   * Error label state
   */
  error?: boolean
  /**
   * Disabled label state
   */
  disabled?: boolean
  /**
   * Optional to add styles
   */
  className?: string
  /**
   * Optional children element of component
   */
  children?: React.ReactNode
}

type LabelElProps = React.HTMLAttributes<HTMLLabelElement>
type LabelProps = LabelBaseProps & LabelElProps & StyledSystemProps

const LabelElement = styled(Box.withComponent('label'))<LabelProps>`
  ${getTypography('bodyMdRegular')}
  color: ${ifProp(
    'disabled',
    selectTheme('input.placeholder.disabled.color'),
    ifProp(
      'error',
      selectTheme('input.placeholder.error.color'),
      selectTheme('input.placeholder.color'),
    ),
  )};
`

const InputLabel: React.FC<LabelProps> = ({
  htmlFor = '',
  error,
  disabled,
  className,
  children,
  ...props
}) => (
  <LabelElement
    {...props}
    className={className}
    htmlFor={htmlFor}
    error={error}
    disabled={disabled}
  >
    {children}
  </LabelElement>
)

export { InputLabel, LabelProps }
