import { Box } from '@creditas-ui/layout'
import { styled } from '@creditas-ui/system'
import { ifProp, selectTheme } from '@creditas-ui/utilities'

type LabelState = {
  active: boolean
  disabled: boolean
}

const InputLabel = styled(Box.withComponent('label'))<LabelState>`
  color: ${ifProp(
    'disabled',
    selectTheme('radio.label.disabled.color'),
    ifProp(
      'active',
      selectTheme('radio.label.active.color'),
      selectTheme('radio.label.color'),
    ),
  )};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  font-size: ${selectTheme('radio.label.fontSize')};
  font-weight: ${selectTheme('radio.label.fontWeight')};
  line-height: ${selectTheme('radio.label.lineHeight')};
  letter-spacing: ${selectTheme('radio.label.letterSpacing')}px;
`

export { InputLabel }
