import * as React from 'react'
import type { ButtonSecondaryProps } from './types'
import { ButtonSecondaryElement } from './ButtonSecondary.style'

const ButtonSecondary = <E extends React.ElementType = 'button'>({
  children,
  disabled = false,
  onClick,
  size = 'large',
  ...props
}: ButtonSecondaryProps<E>) => (
  <ButtonSecondaryElement
    {...props}
    disabled={disabled}
    onClick={onClick && onClick}
    size={size}
  >
    {children}
  </ButtonSecondaryElement>
)

export { ButtonSecondary }
