import { Box, Flex } from '@creditas-ui/layout'
import { useForkRef } from '@creditas-ui/utilities'
import React, { useState } from 'react'
import { InputHelperText } from '../InputHelperText'
import type { InputBoxedProps } from '../InputBoxed'
import { useInputBoxed } from '../InputBoxed/useInputBoxed'
import {
  TextAreaBoxedElement,
  TextAreaCounterText,
  TextAreaInput,
  TextAreaLabel,
} from './TextArea.style'

type TextAreaElProps = {
  /**
   * Optional height of textarea
   */
  height?: string
  /**
   * Optional counter
   */
  counter?: number
}
export type TextAreaProps = Omit<InputBoxedProps, 'Icon'> & TextAreaElProps

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoFocus = false,
      autoComplete = 'off',
      disabled = false,
      component = 'textarea',
      error,
      helperText,
      success,
      label,
      name,
      id,
      value,
      placeholder,
      className,
      counter,
      onChange = () => {
        /* noop */
      },
      onFocus,
      onBlur,
      height,
      ...props
    },
    ref,
  ) => {
    const [charsCounter, setCharsCounter] = useState(value?.length || 0)
    const {
      active,
      focused,
      inputRef,
      handleOnBlur,
      handleOnFocus,
      handleOnClick,
    } = useInputBoxed<HTMLTextAreaElement>({
      value,
      disabled,
      placeholder,
      onBlur,
      onFocus,
      autoFocus,
    })

    const handleRefs = useForkRef<HTMLTextAreaElement | undefined>(
      ref,
      inputRef,
    )

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget
      setCharsCounter(value?.length)
      onChange(event)
    }

    return (
      <Box>
        <TextAreaBoxedElement
          onClick={handleOnClick}
          focused={focused}
          height={height}
        >
          <TextAreaLabel
            htmlFor={id}
            active={active}
            disabled={disabled}
            error={Boolean(error)}
          >
            {label}
          </TextAreaLabel>
          <TextAreaInput
            component={component}
            ref={handleRefs as React.Ref<HTMLInputElement>}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            className={className}
            onChange={handleChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            {...props}
          />
        </TextAreaBoxedElement>

        <Flex justifyContent="space-between">
          {helperText && !success && !error && (
            <InputHelperText>{helperText}</InputHelperText>
          )}
          {success && !error && (
            <InputHelperText success>{success}</InputHelperText>
          )}
          {error && !disabled && (
            <InputHelperText error>{error}</InputHelperText>
          )}
          {counter !== undefined && (
            <TextAreaCounterText>
              {charsCounter}/{counter}
            </TextAreaCounterText>
          )}
        </Flex>
      </Box>
    )
  },
)
