import React from 'react'
import { AccordionDefaultBodyElement } from './AccordionDefaultBody.style'

const AccordionBody: React.FC = ({ children, ...props }) => (
  <AccordionDefaultBodyElement {...props}>
    {children}
  </AccordionDefaultBodyElement>
)

export { AccordionBody }
