import React from 'react'
import type { IconsProps } from '../../types'
import { ColorPropsEnum } from '../../types'

export const IconBellWarning = ({
  size,
  strokeWidth = 1.5,
  color = ColorPropsEnum.black,
}: IconsProps) => (
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      stroke={color}
      fill="none"
      strokeLinejoin="round"
    >
      <path d="M9.708 18.344v.365l-7.33894e-08-.000579615c1.91092e-07 1.26554 1.02588 2.29149 2.29142 2.29158v0l8.9804e-08 8.22808e-12c1.26572.000115936 2.29188-1.02586 2.292-2.29158 1.28241e-08-.000140006 1.28186e-08-.000280013-1.64597e-11-.000420019v-.365" />
      <path d="M13.833 5.751v-.918l1.20322e-08-.000210012c0-1.01222-.820568-1.83279-1.83279-1.83279 -.000140008 0-.000280016 1.6043e-08-.000420024 4.81289e-08v0l9.09948e-08 1.0429e-11c-1.01222-.000116037-1.83288.820358-1.833 1.83258 -1.60499e-08.000140008-1.60569e-08.000280016-2.08527e-11.000420024v.918" />
      <path d="M6.563 10.188v0l-1.86498e-09-.000129318c-3.77841e-07-2.50233 2.02854-4.53087 4.53087-4.53087 8.66679e-05-1.30864e-11.000173336 2.46054e-09.000260004 7.42089e-09h1.813l1.74584e-07-5.00755e-12c2.50233-7.17016e-05 4.53093 2.02841 4.531 4.53074 2.48338e-09 8.66679e-05 2.48004e-09.000173336-1.00187e-11.000260004v0 2.797l-4.69321e-10-1.35751e-06c.000183182.530376.21096 1.03897.585999 1.414l.641.641 -2.43822e-08-2.43813e-08c.375039.375025.585816.883624.586 1.414v0l1.39981e-08.000230014c0 1.04369-.846079 1.88977-1.88977 1.88977 -.000153343 0-.000306685-1.86642e-08-.000460028-5.59925e-08h-10.7209l-8.76231e-08 1.06617e-11c-1.04369.000126985-1.88987-.845849-1.89-1.88954 -1.8657e-08-.000153343-1.86499e-08-.000306685 2.13305e-11-.000460028v0l-4.69319e-10 1.35751e-06c.000183182-.530376.21096-1.03897.585999-1.414l.641-.641 -2.43822e-08 2.43813e-08c.375039-.375025.585816-.883624.586-1.414Z" />
      <path d="M11.9999 11.7452v-2.57345" />
      <path d="M12.1249 14.7028v0c0 .0690356-.0559644.125-.125.125 -.0690356 0-.125-.0559644-.125-.125 0-.0690356.0559644-.125.125-.125" />
      <path d="M11.9999 14.5778h-5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125" />
    </g>
    <path fill="none" d="M0 0h24v24h-24Z" />
  </svg>
)
