import React from 'react'
import type { IconsProps } from '../../types'
import { ColorPropsEnum } from '../../types'

export const IconJustifyLeft1 = ({
  size,
  strokeWidth = 1.5,
  color = ColorPropsEnum.black,
}: IconsProps) => (
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      stroke={color}
      fill="none"
      strokeLinejoin="round"
    >
      <line x1="20" x2="4" y1="4" y2="4" />
      <line x1="17" x2="4" y1="8" y2="8" />
      <line x1="17" x2="4" y1="12" y2="12" />
      <line x1="20" x2="4" y1="16" y2="16" />
      <line x1="12" x2="4" y1="20" y2="20" />
    </g>
    <rect width="24" height="24" fill="none" />
  </svg>
)
