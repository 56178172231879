import { styled } from '@creditas-ui/system'
import type { MediaSizes } from '@creditas-ui/utilities'
import { getTypography, media } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import type {
  TypographyProps,
  TypographyVariants,
  TypographyVariantProp,
} from './types'

const setVariantByBreakpoint = (
  variant: TypographyVariants,
  breakpoint: string,
  theme: object,
) => {
  if (breakpoint === '_') {
    return getTypography(variant)({
      theme,
    })
  }

  return `
    ${media.up(breakpoint as keyof MediaSizes)} {
      ${getTypography(variant)({ theme })}
    }
  `
}

const getTypographyByBreakpoint = (
  breakpointVariants: TypographyVariantProp,
  theme: any,
) => {
  const breakpointVariantsList = Object.entries(breakpointVariants)

  return breakpointVariantsList.map(([breakpoint, variant]: string[]) =>
    setVariantByBreakpoint(variant as TypographyVariants, breakpoint, theme),
  )
}

const base = ({ theme, variant }: any) => {
  if (typeof variant === 'string') {
    return getTypography(variant as TypographyVariants)({ theme })
  }

  if (typeof variant === 'object') {
    return getTypographyByBreakpoint(variant, theme)
  }

  return ''
}

const TypographyElement = styled(Box.withComponent('p'))<TypographyProps>`
  ${base}
`

export { TypographyElement }
