import { styled } from '@creditas-ui/system'
import { media, getTypography, selectTheme } from '@creditas-ui/utilities'
import { AccordionBaseBody } from '../../Base'

const AccordionDefaultBodyElement = styled(AccordionBaseBody)`
  color: ${selectTheme('accordion.default.body.fontColor')};

  ${media.between('xs', '2xl')} {
    ${getTypography('bodyMdLight')}
  }

  ${media.up('2xl')} {
    ${getTypography('bodyXlRegular')}
    padding-right: 20%;
  }
`

export { AccordionDefaultBodyElement }
