import * as React from 'react'

type InputBoxedHookProps = {
  value?: string
  disabled?: boolean
  autoFocus?: boolean
  placeholder?: string
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void
}

type InputBoxedHook<T extends HTMLInputElement | HTMLTextAreaElement> = {
  active: boolean
  focused: boolean
  inputRef: React.MutableRefObject<T | undefined>
  handleOnBlur: (event: React.FormEvent<HTMLInputElement>) => void
  handleOnFocus: (event: React.FormEvent<HTMLInputElement>) => void
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

function useInputBoxed<T extends HTMLInputElement | HTMLTextAreaElement>({
  value,
  disabled,
  autoFocus,
  placeholder,
  onBlur,
  onFocus,
}: InputBoxedHookProps): InputBoxedHook<T> {
  const inputRef = React.useRef<T>()
  const [active, setActive] = React.useState<boolean>(Boolean(value))
  const [focused, setFocused] = React.useState<boolean>(
    Boolean(autoFocus && !disabled),
  )

  React.useEffect(() => {
    if (!value && !autoFocus) {
      setActive(false)
    }
  }, [value, autoFocus])

  React.useEffect(() => {
    const inputValue = value ?? inputRef.current?.value
    if ((inputValue && !active) || placeholder) {
      setActive(true)
    }
  }, [value, placeholder, active])

  const setFocus = () => {
    setFocused(true)
    inputRef.current?.focus()
  }

  const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
    setFocused(false)

    if (!(event.target as T).value) {
      setActive(false)
    }

    if (onBlur) {
      onBlur(event)
    }
  }

  const handleOnFocus = (event: React.FormEvent<HTMLInputElement>) => {
    setFocused(true)
    setActive(true)

    if (onFocus) {
      onFocus(event)
    }
  }

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      event.stopPropagation()

      return
    }

    setFocus()
    setActive(true)
  }

  return {
    active,
    focused,
    inputRef,
    handleOnBlur,
    handleOnFocus,
    handleOnClick,
  }
}

export { useInputBoxed, InputBoxedHook, InputBoxedHookProps }
