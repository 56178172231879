import type { FoudationTokens } from '../../types'

const icons = (tokens: FoudationTokens) => ({
  globalIcons: {
    size: {
      x_small: {
        size: 8,
        strokeWidth: 0.25,
        width: 8,
        height: 8,
      },
      small: {
        size: 16,
        strokeWidth: 0.5,
        width: 16,
        height: 16,
      },
      medium: {
        size: 24,
        strokeWidth: 1,
        width: 24,
        height: 24,
      },
      large: {
        size: 32,
        strokeWidth: 1,
        width: 32,
        height: 32,
      },
      x_large: {
        size: 40,
        strokeWidth: 1,
        width: 40,
        height: 40,
      },
    },
  },

  shapeIcons: {
    size: {
      x_small: {
        size: 80,
        strokeWidth: 0.5, //1px
        width: 80,
        height: 80,
      },

      small: {
        size: 120,
        strokeWidth: 0.5, //1px
        width: 120,
        height: 120,
      },

      medium: {
        size: 160,
        strokeWidth: 0.5, //1px
        width: 160,
        height: 160,
      },

      large: {
        size: 240,
        strokeWidth: 0.5, //1px
        width: 240,
        height: 240,
      },
    },
    shape: {
      color: {
        success: tokens.colors.primary[40],
        error: tokens.colors.error[60],
      },
    },
  },
})

export default icons
