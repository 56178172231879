const calcStrokeDasharray = (radius: number) => 2 * 3.14 * radius

const getProps = (value: number) => ({
  cy: value,
  cx: value,
  r: value,
  strokeDasharray: calcStrokeDasharray(value),
})

export const propsSpinner = {
  xsmall: getProps(5),
  small: getProps(9),
  medium: getProps(13),
  large: getProps(19),
}
