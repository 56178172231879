import { Box, Flex } from '@creditas-ui/layout'
import { ifProp, selectTheme } from '@creditas-ui/utilities'
import { styled, css } from '@creditas-ui/system'
import { ButtonSecondary } from '@creditas-ui/button'
import type { SecondaryButtonProps } from '../types'

const base = css`
  width: 100%;
  height: 100%;

  display: inline-flex;
`

export const Wrapper = styled(Flex)`
  width: 100%;
  min-height: ${selectTheme('modal.footer.minHeight')};
  box-sizing: border-box;

  padding: ${selectTheme('modal.footer.paddingTop')}
    ${selectTheme('modal.base.paddingX')};
  padding-bottom: ${selectTheme('modal.footer.paddingBottom')};
`

export const VerticalAlignedButtonsWrapper = styled(Box)`
  ${base}
  align-items: center;
  flex-direction: column;
`

export const HorizontalAlignedButtonsWrapper = styled(Box)`
  ${base}
  flex-direction: row;
  justify-content: flex-end;
`

export const SecondaryButton = styled(ButtonSecondary)<SecondaryButtonProps>`
  ${ifProp(
    'isVerticalAligned',
    css`
      margin-top: 24px;
    `,
    css`
      margin-right: 40px;
    `,
  )}
`

export const Container = styled(Flex)``
