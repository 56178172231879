import * as React from 'react'
import { QuoteElement, QuoteTitleElement } from './Quote.style'
import type { QuoteBaseProps } from './types'

/**
 * Primary UI component for user interaction
 */
const Quote: React.FC<QuoteBaseProps> = ({
  component = 'blockquote',
  variant = 'neutral',
  children,
  className,
  title,
  ...props
}: QuoteBaseProps) => (
  <QuoteElement
    {...props}
    as={component}
    className={className}
    variant={variant}
  >
    {title && <QuoteTitleElement>{title}</QuoteTitleElement>}
    {children}
  </QuoteElement>
)

export { Quote }
