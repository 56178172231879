import { css } from '@creditas-ui/system'

export const disabledMode = ({ theme: { tag } }: any) => css`
  color: ${tag('disabled.icon.color')};

  cursor: not-allowed;

  svg {
    opacity: 1;

    g {
      circle,
      polyline,
      line,
      path {
        stroke: ${tag('disabled.icon.color')};
      }

      path:first-of-type {
        stroke: none;
      }
    }
  }
`
