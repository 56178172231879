import type { FoudationTokens } from '../../types'

const accordion = (tokens: FoudationTokens) => ({
  base: {
    fontFamily: tokens.fontFamily.base,
    fontColor: tokens.colors.neutral[90],
    disabledFontColor: tokens.colors.neutral[40],
    transition: tokens.motion.xs,
    borderBottom: tokens.stroke.xs,
    borderColor: tokens.colors.neutral['20'],
    medium: {
      paddingBottom: tokens.spacingY['8xl'],
    },
    larger: {
      paddingBottom: tokens.spacingY['12xl'],
    },
  },
  default: {
    body: {
      fontColor: tokens.colors.neutral[80],
    },
  },
  groupBase: {
    title: {
      medium: {
        marginBottom: tokens.spacingBottom['8xl'],
      },
      large: {
        marginBottom: tokens.spacingBottom['16xl'],
      },
    },
  },
  header: {
    medium: {
      paddingTop: tokens.spacingY['8xl'],
    },
    larger: {
      paddingTop: tokens.spacingY['12xl'],
    },
  },
  headerContent: {
    marginRight: tokens.spacingRight['8xl'],
  },
  arrow: {
    color: tokens.colors.neutral['90'],
    marginRight: tokens.spacingRight['8xl'],
  },
  body: {
    medium: {
      marginTop: tokens.spacingTop['3xl'],
      paddingBottom: tokens.spacingY['8xl'],
    },
    large: {
      marginTop: tokens.spacingTop['6xl'],
      paddingBottom: tokens.spacingY['12xl'],
    },
  },
})

export default accordion
