import { styled, css } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import { media, selectTheme, ifProp } from '@creditas-ui/utilities'
import type { AccordionBodyContainerProps } from '../../types'

export const isOpen = ({ theme: { accordion } }: any) => css`
  max-height: 1200px;
  transition: all ${accordion('base.transition')} ease-in;
  visibility: initial;

  ${media.between('xs', '2xl')} {
    padding-top: ${accordion('body.medium.marginTop')};
  }

  ${media.up('2xl')} {
    padding-top: ${accordion('body.large.marginTop')};
  }
`

const AccordionBodyElement = styled(Box)<AccordionBodyContainerProps>`
  transition: all ${selectTheme('accordion.base.transition')} ease-out;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;

  ${ifProp({ isOpen: true }, isOpen)}
`

export { AccordionBodyElement }
