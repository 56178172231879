import { standard } from '@creditas-ui/themes'
import { pickIconsThemePropsHelper } from '@creditas-ui/utilities'

const globalIcons = standard.icons('globalIcons.size')

export const globalIconsProps = pickIconsThemePropsHelper(globalIcons, [
  'size',
  'strokeWidth',
])
export const globalIconsWrapperProps = pickIconsThemePropsHelper(globalIcons, [
  'width',
  'height',
])
