import type { styleFn } from '../styled-system'
import { compose } from '../styled-system'
import * as styledFunctions from './constants'

import type { ComposedType } from './types'

export const composed = (...styles: ComposedType[]): styleFn =>
  compose(...styles.map(style => styledFunctions[style]))

export const composedAllProps: styleFn = compose(
  ...Object.keys(styledFunctions).map(styleFun => styledFunctions[styleFun]),
)
