import { css } from '@creditas-ui/system'
import { variant } from '@creditas-ui/utilities'

export const base = ({ theme: { button } }: any) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  overflow: hidden;
  width: auto;
  color: ${button('primary.fontColor')};
  letter-spacing: ${button('primary.letterSpacing')}px;
  font-family: ${button('primary.fontFamily')};
  background-color: ${button('primary.color')};
  transition: box-shadow ${button('secondary.transitionMotion')};
`

export const sizes = ({ theme: { button } }: any) =>
  variant({
    prop: 'size',
    variants: {
      small: {
        borderRadius: button('primary.radius.small'),
        paddingX: button('primary.spacingX.small.textOnly'),
        paddingY: button('primary.spacingY.small.textOnly'),
        lineHeight: button('primary.lineHeight.small'),
        fontSize: button('primary.fontSize.small'),
        fontWeight: button('primary.fontWeight.small'),
      },
      medium: {
        borderRadius: button('primary.radius.medium'),
        paddingX: button('primary.spacingX.medium.textOnly'),
        paddingY: button('primary.spacingY.medium.textOnly'),
        lineHeight: button('primary.lineHeight.medium'),
        fontSize: button('primary.fontSize.medium'),
        fontWeight: button('primary.fontWeight.medium'),
      },
      large: {
        borderRadius: button('primary.radius.large'),
        paddingX: button('primary.spacingX.large.textOnly'),
        paddingY: button('primary.spacingY.large.textOnly'),
        lineHeight: button('primary.lineHeight.large'),
        fontSize: button('primary.fontSize.large'),
        fontWeight: button('primary.fontWeight.large'),
      },
    },
  })

export const widths = variant({
  prop: 'width',
  variants: {
    flexible: {
      width: 'auto',
    },
    fixed: {
      width: '100%',
    },
  },
})

export const loading = css`
  cursor: default;
  .not-visible-when-loading {
    visibility: hidden;
  }

  .spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
`

export const disabled = ({ theme: { button } }: any) => css`
  color: ${button('primary.disabledFontColor')};
  background-color: ${button('primary.disabledColor')};
  cursor: not-allowed;
`

export const hover = ({ theme: { button } }: any) => css`
  box-shadow: ${button('primary.shadow')};
`

export const focus = ({ theme: { button } }: any) => css`
  box-shadow: ${button('primary.shadow')};
`

export const pressedBase = ({ theme: { button } }: any) => css`
  opacity: ${button('primary.opacity')};
  position: absolute;
  background-color: ${button('primary.pressedColor')};
  border-radius: 50%;
  transform: scale(0);
  animation: ripple ${button('primary.rippleMotion')} linear;
  transition: all ${button('primary.rippleMotion')};

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`
