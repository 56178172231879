import { styled, css } from '@creditas-ui/system'
import { variant } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import type { StatusLabelBaseProps } from './types'

const base = ({ theme: { statusLabel } }: any) => css`
  position: relative;
  font-family: ${statusLabel('base.text.fontFamily')};
  color: ${statusLabel('base.text.color')};
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: inline-block;
    border-radius: 100%;
  }
`

const sizes = ({ theme: { statusLabel } }: any) =>
  variant({
    prop: 'size',
    variants: {
      small: {
        px: `${statusLabel('sizes.small.container.spacingX')}`,
        py: `${statusLabel('sizes.small.container.spacingY')}`,
        pl: `${statusLabel('sizes.small.container.spacingLeft')}`,
        fontSize: statusLabel('sizes.small.text.fontSize'),
        '&:before': {
          width: statusLabel('sizes.small.bullet.width'),
          height: statusLabel('sizes.small.bullet.height'),
        },
      },
      medium: {
        px: `${statusLabel('sizes.medium.container.spacingX')}`,
        py: `${statusLabel('sizes.medium.container.spacingY')}`,
        pl: `${statusLabel('sizes.medium.container.spacingLeft')}`,
        fontSize: statusLabel('sizes.medium.text.fontSize'),
        '&:before': {
          width: statusLabel('sizes.medium.bullet.width'),
          height: statusLabel('sizes.medium.bullet.height'),
        },
      },
    },
  })

const variants = ({ theme: { statusLabel } }: any) =>
  variant({
    variants: {
      error: {
        '&:before': {
          backgroundColor: statusLabel('variants.error.bullet.backgroundColor'),
        },
      },
      information: {
        '&:before': {
          backgroundColor: statusLabel(
            'variants.information.bullet.backgroundColor',
          ),
        },
      },
      success: {
        '&:before': {
          backgroundColor: statusLabel(
            'variants.success.bullet.backgroundColor',
          ),
        },
      },
      warning: {
        '&:before': {
          backgroundColor: statusLabel(
            'variants.warning.bullet.backgroundColor',
          ),
        },
      },
      neutral: {
        '&:before': {
          backgroundColor: statusLabel(
            'variants.neutral.bullet.backgroundColor',
          ),
        },
      },
    },
  })

const disabled = ({ disabled, theme: { statusLabel } }: any) => css`
  ${disabled && `color: ${statusLabel('disabled.text.color')}`}
`

const StatusLabelElement = styled(
  Box.withComponent('span'),
)<StatusLabelBaseProps>`
  ${base};
  ${sizes};
  ${variants};
  ${disabled}
`
export { StatusLabelElement }
