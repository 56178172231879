import React from 'react'
import { IconDeleteDisabled } from '@creditas-ui/icons'
import { GlobalIcons } from '@creditas-ui/global-icons'
import { ButtonSecondary } from '@creditas-ui/button'
import { Typography } from '@creditas-ui/typography'

import { Wrapper } from './Header.template.style'
import { useModalContext } from '../../../hooks'
import type { HeaderBaseProps } from '../../types'

export const HeaderTemplate = ({ title, hideCloseIcon }: HeaderBaseProps) => {
  const { closeModal, allowClose } = useModalContext()
  return (
    <Wrapper>
      <Typography
        variant="bodyMdLight"
        children={title}
        color="neutral.90"
        component="h3"
      />

      {!hideCloseIcon && allowClose && (
        <ButtonSecondary
          onClick={closeModal}
          aria-label="close modal"
          data-testid="icon-close-button"
        >
          <GlobalIcons Icon={IconDeleteDisabled} size="medium" />
        </ButtonSecondary>
      )}
    </Wrapper>
  )
}
