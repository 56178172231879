import { styled, css } from '@creditas-ui/system'
import { getTypography, variant } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import type { QuoteBaseProps } from './types'

const base = ({ theme: { quote } }: any) => css`
  box-sizing: border-box;
  width: ${quote('base.box.width')};
  min-width: ${quote('base.box.minWidth')};
  margin: 0;
  padding-left: ${quote('base.box.paddingLeft')};
  color: ${quote('base.text.color')};
  border-left-width: ${quote('base.box.borderLeftWidth')};
  border-left-style: ${quote('base.box.borderLeftStyle')};
`

const titleElement = ({ theme: { quote } }: any) => css`
  display: block;
  margin-bottom: ${quote('base.title.marginBottom')};
  color: ${quote('base.title.color')};
`

const variantStyles = variant({
  variants: {
    error: {
      borderColor: 'error.60',
    },
    informational: {
      borderColor: 'informational.60',
    },
    neutral: {
      borderLeftColor: 'neutral.15',
    },
    success: {
      borderColor: 'primary.60',
    },
    warning: {
      borderColor: 'warning.60',
    },
  },
})

const QuoteElement = styled(Box.withComponent('blockquote'))<QuoteBaseProps>`
  ${base};
  ${variantStyles};
  ${getTypography('bodyMdRegular')};
`
const QuoteTitleElement = styled(Box.withComponent('span'))<any>`
  ${titleElement};
  ${getTypography('bodyMdBold')};
`
export { QuoteElement, QuoteTitleElement }
