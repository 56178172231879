import type { FoudationTokens } from '../../types'

const textarea = (tokens: FoudationTokens) => ({
  shape: {
    height: '104px',
  },
  focus: {
    padding: `${tokens.spacingY['xl']} 0 ${tokens.spacingY['xl']} ${tokens.spacingX['5xl']}`,
  },
  placeholder: {
    top: tokens.spacingY['5xl'],
    active: {
      top: tokens.spacingY.xl,
    },
  },
  value: {
    height: `calc(100% - ${tokens.spacingY.xl} - ${tokens.lineHeight.xs} - ${tokens.spacingY.xl})`,
  },
  counter: {
    marginLeft: tokens.spacingLeft['3xl'],
  },
})

export default textarea
