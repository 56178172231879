import { styled, css } from '@creditas-ui/system'
import { selectTheme, media, getTypography } from '@creditas-ui/utilities'
import { Flex } from '@creditas-ui/layout'

const mobileVersion = css`
  flex-direction: column;
  height: unset;

  button {
    order: -1;
    align-self: flex-end;
    transform: translate(14px);
  }

  h3 {
    align-self: flex-start;
  }
`

export const Wrapper = styled(Flex)`
  width: 100%;
  min-height: 64px;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  padding: ${selectTheme('spacingY.5xl')} ${selectTheme('spacingX.6xl')};

  button {
    padding: 0;
    margin: 0;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  ${media.down('xs')} {
    ${mobileVersion}
    h3 {
      ${getTypography('headingSmLight')};
    }
  }
`
