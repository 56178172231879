import { css } from '@creditas-ui/system'

export const shimmer = ({ theme: { shimmer } }: any) => css`
  background-image: linear-gradient(
    -90deg,
    transparent 0%,
    ${shimmer('variants.light.color')} 50%,
    transparent 100%
  );

  background-size: 400% 400%;

  animation: shimmer ${shimmer('base.time')} ease-in-out infinite;

  @keyframes shimmer {
    from {
      background-position: 0% 0%;
    }
    to {
      background-position: -135% 0%;
    }
  }
`
