import { styled, css } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'

const base = ({ theme: { accordion } }: any) => css`
  font-family: ${accordion('base.fontFamily')};
  color: ${accordion('base.fontColor')};
  width: 100%;
`

const AccordionElement = styled(Box)`
  ${base};
`

export { AccordionElement }
