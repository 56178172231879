import * as React from 'react'
import { useForkRef } from '@creditas-ui/utilities'
import {
  InputBoxedElement,
  InputLabel,
  Input,
  IconWrapper,
} from './InputBoxed.style'
import { useInputBoxed } from './useInputBoxed'
import type { InputProps } from '../Input'
import { InputHelperText } from '../InputHelperText'

type InputBoxedProps = InputProps & {
  /**
   * Input label element
   */
  label: string
  Icon?: React.FC
}

const InputBoxed = React.forwardRef<HTMLInputElement, InputBoxedProps>(
  (
    {
      type = 'text',
      autoFocus = false,
      autoComplete = 'off',
      disabled = false,
      component,
      error,
      helperText,
      success,
      label,
      name,
      id,
      value,
      placeholder,
      className,
      onChange,
      onFocus,
      onBlur,
      Icon,
      ...props
    },
    ref,
  ) => {
    const {
      active,
      focused,
      inputRef,
      handleOnBlur,
      handleOnFocus,
      handleOnClick,
    } = useInputBoxed<HTMLInputElement>({
      value,
      disabled,
      placeholder,
      onBlur,
      onFocus,
      autoFocus,
    })

    const handleRefs = useForkRef<HTMLInputElement | undefined>(ref, inputRef)

    const testId = props['data-testid'] ?? name ?? label

    return (
      <>
        <InputBoxedElement
          onClick={handleOnClick}
          focused={focused}
          hasIcon={!!Icon}
        >
          <InputLabel
            htmlFor={id || label}
            active={active}
            disabled={disabled}
            error={Boolean(error)}
          >
            {label}
          </InputLabel>
          <Input
            data-testid={testId}
            {...props}
            type={type}
            component={component}
            ref={handleRefs as React.Ref<HTMLInputElement>}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            name={name}
            id={id || label}
            value={value}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />

          {Icon && (
            <IconWrapper disabled={disabled} role="button">
              <Icon />
            </IconWrapper>
          )}
        </InputBoxedElement>

        {helperText && !success && !error && (
          <InputHelperText data-testid={`${testId}-helper-text-info`}>
            {helperText}
          </InputHelperText>
        )}
        {success && !error && (
          <InputHelperText
            success
            data-testid={`${testId}-helper-text-success`}
          >
            {success}
          </InputHelperText>
        )}
        {error && !disabled && (
          <InputHelperText error data-testid={`${testId}-helper-text-error`}>
            {error}
          </InputHelperText>
        )}
      </>
    )
  },
)

export { InputBoxed, InputBoxedProps }
