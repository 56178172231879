import type {FoudationTokens} from '../../types'

const skeleton = (tokens: FoudationTokens) => ({
  color: {
    light: {
      backgroundColor: tokens.colors.neutral[80],
      opacity: tokens.opacity['md'],
    },
    dark: {
      backgroundColor: tokens.colors.neutral[80],
      backgroundImage: `linear-gradient(
        -90deg,
        transparent 40%,
        ${tokens.colors.neutral[90]} 50%,
        transparent 60%
        )`,
    },
  },
  shapes: {
    circle: {
      base: {
        borderRadius: tokens.radius['round'],
      },
      sizes: {
        small: {width: '48px', height: '48px'},
        medium: {width: '80px', height: '80px'},
        large: {width: '120px', height: '120px'},
        xlarge: {width: '160px', height: '160px'},
        '2xlarge': {width: '240px', height: '240px'},
      },
    },
    rectangle: {
      base: {
        borderRadius: tokens.radius['sm'],
        marginBottom: tokens.spacingY['md'],
      },
      heading: {
        height: '40px',
      },
      body: {
        height: '16px',
      },
    },
  },
})

export default skeleton
