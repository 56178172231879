import { styled, css } from '@creditas-ui/system'
import { Box, Flex } from '@creditas-ui/layout'
import { media, selectTheme, ifProp } from '@creditas-ui/utilities'
import type { AccordionArrowContainerProps } from '../../types'

const AccordionHeaderElement = styled(Flex)`
  transition: ${selectTheme('accordion.transition')};
  align-content: center;

  ${media.between('xs', '2xl')} {
    padding-top: ${selectTheme('accordion.header.medium.paddingTop')};
  }

  ${media.up('2xl')} {
    padding-top: ${selectTheme('accordion.header.larger.paddingTop')};
  }
`

const AccordionHeaderContent = styled(Flex)`
  align-self: center;
  width: 100%;
  align-items: center;
  margin-right: ${selectTheme('accordion.header.marginRight')};

  ${media.between('xs', '2xl')} {
    min-height: 24px;
  }

  ${media.up('2xl')} {
    min-height: 32px;
  }
`

const AccordionArrowContainer = styled(Box)<AccordionArrowContainerProps>`
  margin-right: ${selectTheme('accordion.arrow.marginRight')};
  cursor: pointer;

  svg {
    transition: ${selectTheme('accordion.base.transition')};
    width: 100%;
    height: 100%;
  }

  svg [stroke] {
    stroke: ${selectTheme('accordion.arrow.color')};
  }

  ${media.between('xs', '2xl')} {
    width: 24px;
    height: 24px;
  }

  ${media.up('2xl')} {
    width: 32px;
    height: 32px;
  }

  ${ifProp(
    { isOpen: true },
    css`
      svg {
        transform: rotateZ(180deg);
      }
    `,
  )}
`

const AccordionTouchableArea = styled(Box)<{ isOpen: boolean }>`
  position: absolute;
  cursor: pointer;
  z-index: 2;

  ${media.between('xs', '2xl')} {
    padding-bottom: ${props =>
      props.isOpen ? '0' : selectTheme('accordion.body.medium.paddingBottom')};
  }

  ${media.up('2xl')} {
    padding-bottom: ${props =>
      props.isOpen ? '0' : selectTheme('accordion.body.large.paddingBottom')};
  }
`

export {
  AccordionHeaderElement,
  AccordionHeaderContent,
  AccordionArrowContainer,
  AccordionTouchableArea,
}
