import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'
import { Flex } from '@creditas-ui/layout'
import { InputLabel } from './InputLabel'
import { InputHint } from './InputHint'

const RadioElements = styled(Flex)`
  position: relative;
  flex-direction: column;
  word-break: break-word;
  font-family: ${selectTheme('radio.base.fontFamily')};
  margin: ${selectTheme('radio.container.margin')};
  overflow: hidden;
`

const InputLabelStyled = styled(InputLabel)`
  margin: 5px 0 0 0;
`

const InputHintStyled = styled(InputHint)`
  margin: ${selectTheme('radio.hint.margin')};
`

export {
  RadioElements,
  InputLabelStyled as InputLabel,
  InputHintStyled as InputHint,
}
