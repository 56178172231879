import * as React from 'react'
import { TypographyElement } from './Typography.style'
import type { TypographyProps } from './types'

const Typography: React.FC<TypographyProps> = ({
  variant,
  children,
  component,
  ...props
}: TypographyProps) => (
  <TypographyElement variant={variant ?? undefined} as={component} {...props}>
    {children}
  </TypographyElement>
)

export { Typography }
