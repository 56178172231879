import * as React from 'react'

type RadioGroupHookProps = {
  value: string
  onChange?: (selectedValue: string) => void
}

interface RadioGroupHook {
  selectedValue: string
  handleOnChange: (event: React.FormEvent<HTMLInputElement>) => void
}

const useRadioGroup = ({
  value,
  onChange,
}: RadioGroupHookProps): RadioGroupHook => {
  const [selectedValue, setSelectedValue] = React.useState<string>(value)

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement
    const value = element.value

    onChange?.(value)
    setSelectedValue(value)
  }

  return { selectedValue, handleOnChange }
}

export { useRadioGroup }
