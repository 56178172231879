import type { FoudationTokens } from '../../types'

const toast = (tokens: FoudationTokens) => ({
  base: {
    container: {
      padding: {
        paddingTop: tokens.spacingY['5xl'],
        paddingBottom: tokens.spacingY['5xl'],
        paddingLeft: tokens.spacingX['5xl'],
        paddingRight: tokens.spacingX['5xl'],
      },
      borderRadius: tokens.radius['4xl'],
      shadow: tokens.shadow.sm,
    },
  },
  light: {
    container: {
      backgroundColor: tokens.colors.neutral[0],
    },
    text: {
      color: tokens.colors.neutral[90],
    },
    iconRight: {
      color: tokens.colors.neutral[90],
    },
  },
  dark: {
    container: {
      backgroundColor: tokens.colors.neutral[90],
    },
    text: {
      color: tokens.colors.neutral[0],
    },
    iconRight: {
      color: tokens.colors.neutral[0],
    },
  },
})

export default toast
