import { ifNotProp, variant } from '@creditas-ui/utilities'
import { styled, css } from '@creditas-ui/system'
import { Box } from '@creditas-ui/layout'
import { Content } from './shared'
import type { ModalFlexProps } from './types'

const base = ({ theme: { modal } }: any) => css`
  background-color: ${modal('base.backgroundColor')};
  border-width: 0;
  border-radius: ${modal('base.borderRadius')};
  box-shadow: ${modal('base.shadow')};
  padding: 0;
`

const sizes = ({ theme: { modal } }: any) =>
  variant({
    prop: 'size',
    variants: {
      small: {
        width: `min(${modal('base.size.small')}, 90vw)`,
      },
      medium: {
        width: `min(${modal('base.size.medium')}, 90vw)`,
      },
      large: {
        width: `min(${modal('base.size.large')}, 90vw)`,
      },
    },
  })

export const Wrapper = styled(Box.withComponent(Content))<
  Partial<ModalFlexProps>
>`
  ${ifNotProp('resetStyle', base)}
  ${ifNotProp('resetStyle', sizes)}
`
