import type { FoudationTokens } from '../../types'

const badge = (tokens: FoudationTokens) => ({
  base: {
    padding: tokens.spacingY.sm,
    borderRadius: tokens.radius.round,
  },

  disabled: {
    backgroundColor: tokens.colors.neutral[10],
    color: tokens.colors.neutral[100],
  },

  variants: {
    default: {
      backgroundColor: tokens.colors.primary[40],
      color: tokens.colors.neutral[100],
    },

    warning: {
      backgroundColor: tokens.colors.neutral[100],
      color: tokens.colors.primary[40],
    },
  },
})

export default badge
