import { Box as DefaultBox } from '@creditas-ui/layout'
import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'
import { Select as DefaultSelect } from './libs'

const shrinkWhenOpenStyle = css`
  transform: translate(0px, -2px);
`

const shrinkWhenCloseStyle = css`
  transform: translate(0px, 0px);
`

export const Box = styled(DefaultBox)`
  position: relative;
  max-width: ${selectTheme('select.box.maxWidth')};
  width: ${selectTheme('select.content.width')};
  margin: ${selectTheme('select.content.margin')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TriggerContent = styled(DefaultBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${selectTheme('select.triggerContent.width')};
`

export const TriggerInputContainer = styled(DefaultBox)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

export const HintLabel = styled(Typography)`
  margin-top: ${selectTheme('select.hint.margin')};
`
export const Value = styled(DefaultSelect.Value)``

export const Trigger = styled(DefaultSelect.Trigger)`
  all: unset;
  display: inline-flex;
  height: ${selectTheme('select.trigger.height')};
  max-height: ${selectTheme('select.trigger.height')};
  min-height: ${selectTheme('select.trigger.height')};
  align-items: center;
  justify-content: space-between;
  background-color: ${selectTheme('select.trigger.backgroundColor')};
  color: ${selectTheme('select.trigger.color')};
  gap: 5;
  border-radius: ${selectTheme('select.trigger.borderRadius')};
  padding: ${selectTheme('select.trigger.padding')};
  transition: box-shadow ${selectTheme('select.trigger.transition')};
  cursor: pointer;

  &[data-disabled] {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: ${selectTheme('select.trigger.focus.boxShadow')};
  }
`

export const Label = styled(Typography)<{ shrink: boolean }>`
  ${shrinkWhenCloseStyle}
  transition: 450ms;

  ${props => (props.shrink ? shrinkWhenOpenStyle : shrinkWhenCloseStyle)}

  &[data-placeholder-disabled] {
  }
`

export const Content = styled(DefaultSelect.Content)`
  overflow: hidden;
  min-width: ${selectTheme('select.content.minWidth')};
  width: ${selectTheme('select.content.width')};
  height: auto;
  box-shadow: ${selectTheme('select.content.boxShadow')};
  border-radius: ${selectTheme('select.content.borderRadius')};

  position: absolute;
  top: ${selectTheme('select.content.top')};
  left: ${selectTheme('select.content.left')};
`

export const Viewport = styled(DefaultSelect.Viewport)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
`

export const Item = styled(DefaultSelect.Item)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${selectTheme('select.item.backgroundColor')};
  color: ${selectTheme('select.item.color')};
  position: relative;
  padding: ${selectTheme('select.item.padding')};
  user-select: none;
  gap: 5;
  cursor: pointer;

  &:focus {
    background-color: ${selectTheme('select.item.focus.backgroundColor')};
  }
`
