import type { FoudationTokens } from '../../types'

const progressIndicator = (tokens: FoudationTokens) => ({
  spinner: {
    baseBorderColor: tokens.colors.neutral[100],
    progressBorderColor: tokens.colors.neutral[90],
    baseOpacity: tokens.opacity['sm'],
    strokeWidth: tokens.stroke.xs,
    borderRadius: tokens.radius.round,
    motion: tokens.motion.md,
    spacing: tokens.spacingX['xs'],
  },
  bar: {
    determinate: {
      base: {
        backgroundColor: tokens.colors.neutral[10],
        opacity: tokens.opacity['sm'],
        borderRadius: tokens.radius['xl'],
        size: {
          xsmall: 1,
          small: 2,
          medium: 4,
          large: 8,
        },
      },
      bar: {
        motion: tokens.motion.md,
        color: tokens.colors.primary[40],
      },
    },
  },
})

export default progressIndicator
