import * as React from 'react'
import { AccordionElement } from './Accordion.style'
import type { AccordionProps } from '../types'

const Accordion: React.FC<AccordionProps> = ({
  children,
  ...props
}: AccordionProps) => <AccordionElement {...props}>{children}</AccordionElement>

export { Accordion }
