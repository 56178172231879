import { css, styled } from '@creditas-ui/system'
import { variant } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import type { SpinnerIndeterminateProps } from '../../types'

const opacityToHex = (opacityToken: any) => {
  const numberToken = Math.round(parseFloat(opacityToken) * 100) / 100
  const alpha = Math.round(numberToken * 255)
  const hex = (alpha + 0x10000).toString(16).substr(-2).toUpperCase()
  return hex
}

const base = ({ theme: { progressIndicator } }: any) => css`
  content: '';
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: ${progressIndicator('spinner.spacing')};
  border-color: ${progressIndicator('spinner.baseBorderColor')}${opacityToHex(
      progressIndicator('spinner.baseOpacity'),
    )} ${progressIndicator('spinner.baseBorderColor')}${opacityToHex(
      progressIndicator('spinner.baseOpacity'),
    )}
    ${progressIndicator('spinner.baseBorderColor')}${opacityToHex(
      progressIndicator('spinner.baseOpacity'),
    )} ${progressIndicator('spinner.progressBorderColor')};
  border-width: ${progressIndicator('spinner.strokeWidth')};
  border-radius: ${progressIndicator('spinner.borderRadius')};
  border-style: solid;
  animation: spin ${progressIndicator('spinner.motion')} linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`

const sizes = variant({
  prop: 'size',
  variants: {
    xsmall: {
      width: '12px',
      height: '12px',
    },
    small: {
      width: '20px',
      height: '20px',
    },
    medium: {
      width: '28px',
      height: '28px',
    },
    large: {
      width: '40px',
      height: '40px',
    },
  },
})

export const SpinnerIndeterminateElement = styled(
  Box.withComponent('span'),
)<SpinnerIndeterminateProps>`
  ${base};
  ${sizes};
`
