import { styled, css } from '@creditas-ui/system'
import {
  variant,
  selectTheme,
  compose,
  space,
  layout,
} from '@creditas-ui/utilities'
import type { SizeType } from '../../types'

const path = 'progressIndicator.spinner'

const base = css`
  display: inline-flex;
`

const sizeStyles = () =>
  variant({
    prop: 'size',
    variants: {
      xsmall: { width: 12, height: 12 },
      small: { width: 20, height: 20 },
      medium: { width: 28, height: 28 },
      large: { width: 40, height: 40 },
    },
  })

const Circle = styled('circle')`
  stroke-width: ${selectTheme(`${path}.strokeWidth`)};
  fill: none;
  transform: translate(1px, 1px);
  transition: ${selectTheme(`${path}.motion`)};
`

export const BaseCircle = styled(Circle)`
  stroke: ${selectTheme(`${path}.baseBorderColor`)};
  opacity: ${selectTheme(`${path}.baseOpacity`)};
`

export const ProgressbarCircle = styled(Circle)`
  stroke: ${selectTheme(`${path}.progressBorderColor`)};
`

export const ContainerElement = styled('svg')<{ size: SizeType }>`
  ${base}
  ${sizeStyles}
  ${compose(layout, space)}
`
