import * as React from 'react'

import { ProgressCircle as SpinnerDeterminate } from './ProgressCircle'
import { Spinner as InfinityCircle } from './Spinner'
import type { SpinnerIndeterminateProps } from '../types'

export const ProgressCircle = ({
  progress = -1,
  accessibilityMessage,
  size,
  className,
}: SpinnerIndeterminateProps) =>
  progress >= 0 ? (
    <SpinnerDeterminate progress={progress} size={size} className={className} />
  ) : (
    <InfinityCircle
      accessibilityMessage={accessibilityMessage}
      size={size}
      className={className}
    />
  )
