import React from 'react'

const createRippleElement = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
) => {
  const button = event.currentTarget

  const circle = document.createElement('div')
  const diameter = Math.max(button.clientWidth, button.clientHeight)
  const radius = diameter / 2

  circle.style.width = `${diameter}px`
  circle.style.height = `${diameter}px`
  circle.style.left = `${event.clientX - button.offsetLeft - radius}px`
  circle.style.top = `${event.clientY - button.offsetTop - radius}px`
  circle.classList.add('ripple')

  const ripple = button.getElementsByClassName('ripple')[0]

  if (ripple) {
    ripple.remove()
  }

  button.appendChild(circle)
}

const removeRippleElement = (buttonRef: React.RefObject<HTMLButtonElement>) => {
  buttonRef.current?.querySelector('.ripple')?.remove()
}

interface Props {
  buttonRef: React.RefObject<HTMLButtonElement>
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const useButton = ({
  buttonRef,
  disabled = false,
  loading = false,
  onClick = () => {
    /* noop */
  },
}: Props) => {
  // create ripple logic
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!loading) {
      createRippleElement(event)
      onClick(event)
    }
  }
  // remove ripple logic
  React.useEffect(() => {
    if (disabled || loading) removeRippleElement(buttonRef)
  }, [disabled, loading, buttonRef])

  return { handleClick } // handlers
}
