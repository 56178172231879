import type { FoudationTokens } from '../../types'

const switch_t = (tokens: FoudationTokens) => ({
  container: {
    borderRadius: tokens.radius['4xl'],
    backgroundColor: tokens.colors.neutral[15],
    checked: {
      backgroundColor: tokens.colors.primary[40],
    },
    disabled: {
      backgroundColor: tokens.colors.neutral[15],
    },
  },
  thumb: {
    backgroundColor: tokens.colors.neutral[0],
    disabled: {
      backgroundColor: tokens.colors.neutral[40],
    },
  },
})

export default switch_t
