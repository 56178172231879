import React, { useLayoutEffect, useRef, useState } from 'react'
import { useAccordionItemContext } from '../../AccordionItem/AccordionItemContext'
import { AccordionArrowIcon } from '../../AccordionIcon'
import {
  AccordionHeaderElement,
  AccordionHeaderContent,
  AccordionArrowContainer,
  AccordionTouchableArea,
} from './AccordionBaseHeader.style'

const AccordionBaseHeader: React.FC = ({ children, ...props }) => {
  const headerElement = useRef({} as HTMLDivElement)
  const { isDisabled, isOpen, setIsOpen } = useAccordionItemContext()
  const [touchableAreaWidth, setTouchableAreaWidth] = useState<number>()
  const [touchableAreaHeight, setTouchableAreaHeight] = useState<number>()

  function setTouchableAreaDimensions() {
    setTouchableAreaHeight(headerElement.current?.clientHeight)
    setTouchableAreaWidth(headerElement.current?.clientWidth)
  }

  useLayoutEffect(() => {
    setTimeout(
      setTouchableAreaDimensions,
      headerElement.current.clientWidth > 0 ? 0 : 300,
    )
  }, [])

  return (
    <>
      <AccordionTouchableArea
        width={touchableAreaWidth}
        height={touchableAreaHeight}
        isOpen={isOpen}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        data-testid="accordion-header-touchable"
      />
      <AccordionHeaderElement {...props} ref={headerElement}>
        <AccordionHeaderContent>{children}</AccordionHeaderContent>

        <AccordionArrowContainer isOpen={isOpen}>
          <AccordionArrowIcon />
        </AccordionArrowContainer>
      </AccordionHeaderElement>
    </>
  )
}

export { AccordionBaseHeader }
