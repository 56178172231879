import type { FoudationTokens } from '../../types'

const input = (tokens: FoudationTokens) => ({
  base: {
    backgroundColor: tokens.colors.neutral[0],
    borderRadius: tokens.radius['4xl'],
    shadow: tokens.shadow.lg,
    paddingX: tokens.spacingX['6xl'],
    margin: tokens.spacingX['5xl'],
    size: {
      small: '320px',
      medium: '600px',
      large: '800px',
    },
  },

  empty: {
    footer: {
      height: tokens.spacingY['12xl'],
    },
  },

  header: {
    paddingY: tokens.spacingY['5xl'],
  },

  content: {
    paddingTop: tokens.spacingY['10xl'],
    paddingBottom: tokens.spacingY['5xl'],
    marginRight: tokens.spacingRight['12xl'],
  },

  footer: {
    paddingTop: tokens.spacingY['5xl'],
    paddingBottom: tokens.spacingY['8xl'],
    minHeight: tokens.spacingY['12xl'],
  },
})

export default input
