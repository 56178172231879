import type { FoudationTokens } from '../../types'

const input = (tokens: FoudationTokens) => ({
  shape: {
    borderRadius: tokens.radius['4xl'],
    padding: `0 ${tokens.spacingX['5xl']}`,
    color: tokens.colors.neutral[90],
    backgroundColor: tokens.colors.neutral[10],
    boxShadow: tokens.shadow.sm,
    transition: tokens.motion.sm,
    disabled: {
      color: tokens.colors.neutral[40],
    },
    focus: {
      backgroundColor: tokens.colors.neutral[0],
    },
  },
  placeholder: {
    color: tokens.colors.neutral[80],
    transition: tokens.motion.xs,
    active: {
      top: tokens.spacingY.xl,
    },
    disabled: {
      color: tokens.colors.neutral[40],
    },
    error: {
      color: tokens.colors.error[60],
    },
  },
  helperText: {
    padding: `${tokens.spacingTop.md} 0 0 0`,
    color: tokens.colors.neutral[80],
    error: {
      color: tokens.colors.error[60],
    },
    success: {
      color: tokens.colors.primary[80],
    },
  },
  focus: {
    backgroundColor: tokens.colors.neutral[0],
  },
  value: {
    marginTop: tokens.spacingTop['7xl'],
  },
  icon: {
    color: tokens.colors.neutral[90],
    marginLeft: tokens.spacingLeft.md,
    marginTop: tokens.spacingTop['5xl'],
    paddingRight: `calc(${tokens.spacingRight['5xl']} + 25px)`,
  },
})

export default input
