import React from 'react'
import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import type { FooterBaseProps } from '../../types'

import {
  VerticalAlignedButtonsWrapper,
  HorizontalAlignedButtonsWrapper,
  SecondaryButton,
} from '../ModalFooter.style'

const VerticalAlignedButtons = ({
  primaryLabel,
  secondaryLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}: FooterBaseProps) => (
  <VerticalAlignedButtonsWrapper>
    <ButtonPrimaryTextOnly
      width="fixed"
      maxWidth={300}
      children={primaryLabel}
      size="medium"
      onClick={onPrimaryButtonClick}
    />

    {!!primaryLabel && !!secondaryLabel && (
      <SecondaryButton
        data-testid="modal-secondary-label"
        isVerticalAligned
        onClick={onSecondaryButtonClick}
        children={secondaryLabel}
      />
    )}
  </VerticalAlignedButtonsWrapper>
)

const HorizontalAlignedButtons = ({
  primaryLabel,
  secondaryLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}: FooterBaseProps) => (
  <HorizontalAlignedButtonsWrapper data-testid="modal-footer-horizontal-alignment-wrapper">
    <SecondaryButton
      className="button-secondary"
      onClick={onSecondaryButtonClick}
      children={secondaryLabel}
    />

    <ButtonPrimaryTextOnly
      children={primaryLabel}
      size="medium"
      onClick={onPrimaryButtonClick}
    />
  </HorizontalAlignedButtonsWrapper>
)

const helperVariant = {
  verticalAlignedButtons: VerticalAlignedButtons,
  horizontalAlignedButtons: HorizontalAlignedButtons,
}

export default helperVariant
