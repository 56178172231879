import * as React from 'react'
import { StatusLabelElement } from './StatusLabel.style'
import type { StatusLabelProps } from './types'

const StatusLabel: React.FC<StatusLabelProps> = ({
  component = 'span',
  size = 'medium',
  variant = 'neutral',
  children,
  className,
  ...props
}: StatusLabelProps) => (
  <StatusLabelElement
    {...props}
    as={component}
    className={className}
    size={size}
    variant={variant}
  >
    {children}
  </StatusLabelElement>
)

export { StatusLabel }
