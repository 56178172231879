import { styled, css } from '@creditas-ui/system'
import type { StyledSystemProps } from '@creditas-ui/utilities'
import { ifProp, composedAllProps } from '@creditas-ui/utilities'

type BoxProps = StyledSystemProps & {
  /**
   * Box vertical margin
   */
  spaceY?: string
  /**
   * Box horizontal margin
   */
  spaceX?: string
}

const Box = styled.div<BoxProps>`
  ${composedAllProps}

  ${ifProp(
    'spaceY',
    ({ spaceY }) => css`
      & > :not([hidden]) ~ :not([hidden]) {
        margin-top: ${spaceY};
      }
    `,
  )}

  ${ifProp(
    'spaceX',
    ({ spaceX }) => css`
      & > :not([hidden]) ~ :not([hidden]) {
        margin-left: ${spaceX};
      }
    `,
  )}
`

export { Box, BoxProps }
