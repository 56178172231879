import type { FoudationTokens } from '../../types'

const radio = (tokens: FoudationTokens) => ({
  base: {
    fontFamily: tokens.fontFamily.base,
  },
  container: {
    margin: `0 0 0 ${tokens.spacingLeft['3xl']}`,
  },
  shape: {
    width: '32px',
    height: '32px',
    background: 'transparent',
    radius: tokens.radius.round,
    borderWidth: tokens.stroke.xs,
    borderColor: tokens.colors.neutral[80],
    outter: {
      width: '40px',
      height: '40px',
    },
    ripple: {
      background: tokens.colors.primary[40],
      opacity: tokens.opacity.md,
      transition: tokens.motion.xs,
      active: {
        opacity: tokens.opacity.sm,
      },
    },
    active: {
      background: tokens.colors.primary[40],
      borderColor: tokens.colors.primary[40],
    },
    disabled: {
      borderColor: tokens.colors.neutral[40],
      opacity: tokens.opacity.md,
    },
    error: {
      borderColor: tokens.colors.error[60],
    },
  },
  label: {
    color: tokens.colors.neutral[80],
    fontSize: tokens.fontSizes.md,
    fontWeight: tokens.fontWeight.sm,
    lineHeight: tokens.lineHeight.md,
    letterSpacing: tokens.letterSpacing.xs,
    active: {
      color: tokens.colors.neutral[90],
    },
    disabled: {
      color: tokens.colors.neutral[40],
    },
  },
  hint: {
    margin: `${tokens.spacingTop.sm} 0 0 0`,
    color: tokens.colors.neutral[60],
    fontSize: tokens.fontSizes.sm,
    fontWeight: tokens.fontWeight.sm,
    lineHeight: tokens.lineHeight.sm,
    letterSpacing: tokens.letterSpacing.xs,
  },
})

export default radio
