import type { FoudationTokens } from '../../types'

const button = (tokens: FoudationTokens) => ({
  primary: {
    fontFamily: tokens.fontFamily.base,
    fontColor: tokens.colors.neutral[90],
    disabledFontColor: tokens.colors.neutral[40],
    color: tokens.colors.primary[40],
    disabledColor: tokens.colors.neutral[10],
    pressedColor: tokens.colors.neutral[90],
    letterSpacing: tokens.letterSpacing.xs,
    shadow: tokens.shadow.md,
    opacity: tokens.opacity['sm'],
    rippleMotion: tokens.motion.xs,
    transitionMotion: tokens.motion.xs,
    radius: {
      small: tokens.radius.lg,
      medium: tokens.radius.xl,
      large: tokens.radius['4xl'],
    },
    fontSize: {
      small: tokens.fontSizes.xs,
      medium: tokens.fontSizes.sm,
      large: tokens.fontSizes.md,
    },
    fontWeight: {
      small: tokens.fontWeight.sm,
      medium: tokens.fontWeight.sm,
      large: tokens.fontWeight.md,
    },
    lineHeight: {
      small: tokens.lineHeight.xs,
      medium: tokens.lineHeight.sm,
      large: tokens.lineHeight.md,
    },
    arrowSpacingLeft: {
      medium: tokens.spacingLeft['10xl'],
      large: tokens.spacingLeft['12xl'],
    },
    spacingX: {
      small: {
        textOnly: tokens.spacingX.xl,
      },
      medium: {
        withArrow: tokens.spacingX['5xl'],
        textOnly: tokens.spacingX['5xl'],
        arrowOnly: tokens.spacingX.lg,
      },
      large: {
        withArrow: tokens.spacingX['6xl'],
        textOnly: tokens.spacingX['6xl'],
        arrowOnly: tokens.spacingX['2xl'],
      },
    },
    spacingY: {
      small: {
        textOnly: tokens.spacingY.md,
      },
      medium: {
        withArrow: tokens.spacingY.lg,
        textOnly: tokens.spacingY.xl,
        arrowOnly: tokens.spacingY.lg,
      },
      large: {
        withArrow: tokens.spacingY['2xl'],
        textOnly: tokens.spacingY['4xl'],
        arrowOnly: tokens.spacingY['2xl'],
      },
    },
  },
  secondary: {
    fontColor: tokens.colors.primary[80],
    disabledFontColor: tokens.colors.neutral[40],
    opacityHover: tokens.opacity.lg,
    transitionMotion: tokens.motion.xs,
    padding: tokens.spacingY.sm,
  },
})

export default button
