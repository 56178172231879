import React from 'react'

import { Box } from '@creditas-ui/layout'

import type { HeaderElementProps } from '../types'
import { HeaderTemplate } from './variants'

export const ModalHeader = ({
  children,
  title,
  hideCloseIcon,
  ...rest
}: HeaderElementProps) => (
  <Box {...rest}>
    {children || <HeaderTemplate title={title} hideCloseIcon={hideCloseIcon} />}
  </Box>
)
