import { styled } from '@creditas-ui/system'
import type { BoxProps } from './Box'
import { Box } from './Box'

type FlexProps = BoxProps

const Flex = styled(Box)<FlexProps>``

Flex.defaultProps = {
  display: 'flex',
}

export { Flex, FlexProps as FlexBoxProps }
