import React from 'react'
import { useAccordionItemContext } from '../../AccordionItem/AccordionItemContext'
import { AccordionBodyElement } from './AccordionBaseBody.style'

const AccordionBaseBody: React.FC = ({ children, ...props }) => {
  const { isOpen, isDisabled } = useAccordionItemContext()

  return (
    <AccordionBodyElement
      {...props}
      isOpen={isOpen}
      data-testid="accordion-body"
    >
      {isDisabled ? undefined : children}
    </AccordionBodyElement>
  )
}

export { AccordionBaseBody }
