import React from 'react'
import { Typography } from '@creditas-ui/typography'

import { Select as DefaultSelect } from './libs'
import type { SelectProps } from './types'

import { Item } from './Select.style'

export type SelectOptionsProps = Pick<SelectProps, 'options' | 'disabled'>

const Options = ({ options, disabled }: SelectOptionsProps) => {
  if (!options) return null

  return (
    <>
      {options.map(item => (
        <Item key={item.value} value={item.value} disabled={item.disabled}>
          <DefaultSelect.ItemText>
            <Typography
              variant="bodyMdRegular"
              component="span"
              color={disabled || item.disabled ? 'neutral.40' : 'neutral.90'}
            >
              {item.label}
            </Typography>
          </DefaultSelect.ItemText>
          <DefaultSelect.ItemIndicator />
        </Item>
      ))}
    </>
  )
}

export default Options
