import React from 'react'
import type { IconsProps } from '../../types'
import { ColorPropsEnum } from '../../types'

export const IconDeleteDisabled = ({
  size,
  strokeWidth = 1.5,
  color = ColorPropsEnum.black,
}: IconsProps) => (
  <svg
    width={size}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none">
      <path d="M0 0h24v24h-24Z" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M8 8l8 8"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16 8l-8 8"
      />
    </g>
  </svg>
)
