import { css } from '@creditas-ui/system'

export const colorizeIcon = (color: string) => css`
  svg {
    g {
      circle,
      polyline,
      line,
      path {
        stroke: ${color};
      }

      path:first-of-type {
        stroke: none;
      }
    }
  }
`
