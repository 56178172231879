import * as React from 'react'
import type { BoxProps } from '@creditas-ui/layout'
import { Box } from '@creditas-ui/layout'
import { useRadioGroup } from './hooks'

type RadioGroupProps = Omit<BoxProps, 'color'> & {
  /**
   * Optional change html element of component
   */
  component?: React.ElementType
  /**
   * Optional selected input value
   */
  value?: string
  /**
   * Optional onChange event
   */
  onChange?: (selectedValue: string) => void
  /**
   * Optional children element of component
   */
  children: React.ReactElement | React.ReactElement[]
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  component = 'div',
  value = '',
  onChange,
  children,
  ...props
}) => {
  const { selectedValue, handleOnChange } = useRadioGroup({ value, onChange })

  return (
    <Box {...props} as={component}>
      {React.Children.map(children, (child: React.ReactElement) =>
        React.cloneElement(child, {
          checked: selectedValue === child.props.value,
          onChange: handleOnChange,
        }),
      )}
    </Box>
  )
}

export { RadioGroup, RadioGroupProps }
