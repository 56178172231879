import * as React from 'react'
import { styled } from '@creditas-ui/system'
import type { StyledSystemProps } from '@creditas-ui/utilities'
import {
  ifProp,
  ifNotProp,
  ignorePropsForwarding,
} from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import { ProgressCircle } from '@creditas-ui/progress-indicator'
import {
  pressedBase,
  focus,
  hover,
  disabled,
  loading,
  widths,
  sizes,
  base,
} from './ButtonPrimaryTextOnly.style'
import { useButton } from '../useButton'

type ButtonPrimaryTextOnlyBaseProps = {
  /**
   * Button text element
   */
  children: React.ReactNode
  /**
   * Optional sizes of button
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Optional width of button
   */
  width?: 'flexible' | 'fixed'
  /**
   * Optional loading button
   */
  loading?: boolean
  /**
   * Optional disabled button
   */
  disabled?: boolean
  /**
   * Optional handle click
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

type DynamicAsProp<E extends React.ElementType> = {
  /**
   * Optional HTML tag
   */
  as?: E
}
type DynamicElProps<E extends React.ElementType> = DynamicAsProp<E> &
  Omit<React.ComponentProps<E>, keyof ButtonPrimaryTextOnlyBaseProps>
type ButtonStyledProps = Omit<
  StyledSystemProps,
  keyof ButtonPrimaryTextOnlyBaseProps
>
type ButtonPrimaryTextOnlyProps<
  E extends React.ElementType = React.ElementType,
> = ButtonPrimaryTextOnlyBaseProps & DynamicElProps<E> & ButtonStyledProps

const ButtonElement = styled(Box.withComponent('button'), {
  shouldForwardProp: ignorePropsForwarding('loading'),
})<ButtonPrimaryTextOnlyProps>`
  ${base}
  ${sizes}
  ${ifNotProp({ size: 'small' }, widths)}
  ${ifProp({ loading: true }, loading)};
  ${ifProp({ disabled: true }, disabled)};

  &:hover {
    ${ifProp({ disabled: false, loading: false }, hover)};
  }

  &:focus {
    ${ifProp({ disabled: false, loading: false }, focus)};
    outline: 0;
  }

  div.ripple {
    ${ifProp({ disabled: false, loading: false }, pressedBase)};
  }
`

type spinnerSize = { [key: string]: 'xsmall' | 'small' | 'medium' | 'large' }

const buttonToSpinnerSize: spinnerSize = {
  small: 'xsmall',
  medium: 'small',
  large: 'medium',
}

const ButtonPrimaryTextOnly = <E extends React.ElementType = 'button'>({
  children,
  size = 'medium',
  width = 'flexible',
  loading = false,
  disabled = false,
  onClick,
  ...props
}: ButtonPrimaryTextOnlyProps<E>) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const { handleClick } = useButton({ buttonRef, loading, disabled, onClick })

  return (
    <ButtonElement
      ref={buttonRef}
      {...props}
      size={size}
      width={width}
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
    >
      <span className="not-visible-when-loading">{children}</span>
      {loading && (
        <ProgressCircle
          className="spinner"
          accessibilityMessage={children}
          size={buttonToSpinnerSize[size]}
        />
      )}
    </ButtonElement>
  )
}

export { ButtonPrimaryTextOnlyProps, ButtonPrimaryTextOnly }
