import { styled, css } from '@creditas-ui/system'
import { variant } from '@creditas-ui/utilities'
import { Box } from '@creditas-ui/layout'
import { globalIconsWrapperProps } from './constants'

import type { GlobalIconsElementProps } from './types'

const base = css`
  display: flex;
`

const sizes = variant({
  prop: 'size',
  variants: globalIconsWrapperProps,
})

export const GlobalIconElement = styled(Box.withComponent('i'))<
  Partial<GlobalIconsElementProps>
>`
  ${base};
  ${sizes};
`
