import type { FoudationTokens } from '../../types'

const avatar = (tokens: FoudationTokens) => ({
  base: {
    borderRadius: tokens.radius.round,
    opacity: tokens.opacity.sm,
    sizes: {
      xs: '24px',
      sm: '32px',
      md: '40px',
      lg: '48px',
      xl: '56px',
    },
  },
  icon: {
    opacity: tokens.opacity.md,
    obj: null,
  },
  light: {
    backgroundColor: tokens.colors.neutral['90'],
    letter: {
      color: tokens.colors.neutral['90'],
    },
  },
  dark: {
    backgroundColor: tokens.colors.neutral['0'],
    letter: {
      color: tokens.colors.neutral['0'],
    },
  },
})

export default avatar
