import React from 'react'
import type { AccordionItemContainerProps } from '../types'
import { AccordionItemElement } from './AccordionItem.style'
import { AccordionItemProvider } from './AccordionItemContext'

const AccordionItem: React.FC<AccordionItemContainerProps> = ({
  children,
  disabled,
  open,
  onChange,
  ...props
}) => (
  <AccordionItemProvider
    onChange={onChange}
    isDisabled={disabled}
    isOpen={open}
  >
    <AccordionItemElement
      {...props}
      disabled={disabled}
      data-testid="accordion-item"
    >
      {children}
    </AccordionItemElement>
  </AccordionItemProvider>
)

export { AccordionItem }
