/* eslint-disable max-classes-per-file */

class BreakpointNotFoundError extends Error {
  constructor(breakpoint: string) {
    super(`Breakpoint '${breakpoint}' not found`)
  }
}

class NextBreakpointNotFoundError extends Error {
  constructor(breakpoint: string) {
    super(`Next breakpoint for '${breakpoint}' not found`)
  }
}

export { BreakpointNotFoundError, NextBreakpointNotFoundError }
