import { Box } from '@creditas-ui/layout'
import { css, styled } from '@creditas-ui/system'
import { getTypography, ifProp } from '@creditas-ui/utilities'
import type { ButtonSecondaryProps } from './types'

export const base = ({ theme: { button } }: any) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  overflow: hidden;
  color: ${button('secondary.fontColor')};
  background-color: transparent;
  transition: all ${button('secondary.transitionMotion')};
  padding: ${button('secondary.padding')};
`

export const disabled = ({ theme: { button } }: any) => css`
  color: ${button('secondary.disabledFontColor')};
  cursor: not-allowed;
`

export const hover = ({ theme: { button } }: any) => css`
  opacity: ${button('secondary.opacityHover')};
`

const sizesSelector = {
  medium: getTypography('bodySmMedium'),
  large: getTypography('bodyMdMedium'),
}

export const ButtonSecondaryElement = styled(
  Box.withComponent('button'),
)<ButtonSecondaryProps>`
  ${base}
  ${ifProp({ disabled: true }, disabled)};

  ${({ size }) => sizesSelector[size!]}

  &:hover {
    ${ifProp({ disabled: false }, hover)};
  }
`
