import React, { useEffect, useState } from 'react'
import type { InputBoxedProps } from '../InputBoxed'
import { InputBoxed } from '../InputBoxed'

export type InputCurrencyProps = Omit<InputBoxedProps, 'onChange'> & {
  decimalSeparator?: string
  prefix?: string
  precision?: number
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    rawValue: number,
  ) => void
}

const THOUSANDS_LENGTH = 3
const DECIMALS_LENGTH = 2

const InputCurrency = React.forwardRef<HTMLInputElement, InputCurrencyProps>(
  (
    {
      decimalSeparator = ',',
      precision = DECIMALS_LENGTH,
      prefix = 'R$',
      onChange,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('')

    function isNumber(number: string) {
      return (
        !number.match(/[^0-9]/gi)?.length && !Number.isNaN(parseInt(number, 10))
      )
    }

    function getNumberWithDots(number: string) {
      let count = 0
      return !number || number === '0'
        ? '0'
        : number
            .split('')
            .reverse()
            .reduce((acc, value) => {
              if (count === THOUSANDS_LENGTH) {
                count = 1
                return `${value}.${acc}`
              }

              count++
              return `${value}${acc}`
            }, '')
    }

    function getRawNumber(n: string) {
      return n.length === DECIMALS_LENGTH
        ? parseFloat(`0.${n}`)
        : parseFloat(`${n.slice(0, n.length - 2)}.${n.slice(n.length - 2)}`)
    }

    function getFormattedNumber(number: string) {
      if (!isNumber(number)) return ''

      const isOnlyDecimals = number.length - precision <= 0
      const decimals = isOnlyDecimals
        ? number
        : number.slice(number.length - precision, number.length)
      const leftNumber = isOnlyDecimals
        ? '0'
        : number.slice(0, number.length - precision).replace(/^0+/g, '')

      const numberWithDots = getNumberWithDots(leftNumber)
      const separator = precision > 0 ? decimalSeparator : ''
      const formattedValue = `${numberWithDots}${separator}${decimals}`

      return `${prefix} ${formattedValue}`
    }

    function onChangeInput(e: React.FormEvent<HTMLInputElement>) {
      const number = e.currentTarget.value
        .replace(/\.|,| /g, '')
        .replace(prefix, '') as string

      const formattedNumber = getFormattedNumber(number)
      const rawNumber = getRawNumber(number)

      setInputValue(formattedNumber)
      if (onChange) onChange(e, rawNumber)
    }

    function updateInputValue() {
      const valueStr = props.value?.toString() || ''

      if (
        getRawNumber(valueStr) !==
        (inputValue && getRawNumber(inputValue.toString()))
      ) {
        const hasDecimals =
          valueStr[valueStr.length - (precision + 1)]?.match(/[.]/)

        if (hasDecimals) {
          setInputValue(getFormattedNumber(valueStr.replace('.', '')))
        } else {
          setInputValue(getFormattedNumber(`${valueStr}00`))
        }
      }
    }

    useEffect(() => {
      updateInputValue()
    }, [props.value])

    return (
      <InputBoxed
        {...props}
        onChange={e => onChangeInput(e)}
        value={inputValue}
        ref={ref}
      />
    )
  },
)

export { InputCurrency }
