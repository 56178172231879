import type { FoudationTokens } from '../../types'

const dropdown = (tokens: FoudationTokens) => ({
  shape: {
    borderRadius: tokens.radius['4xl'],
    fontFamily: tokens.fontFamily.base,
    height: tokens.spacingY['16xl'],
    padding: `0 ${tokens.spacingX['5xl']}`,
    color: tokens.colors.neutral[80],
    backgroundColor: tokens.colors.neutral[10],
    arrow: {
      color: tokens.colors.neutral[80],
    },
    icon: {
      right: tokens.spacingRight['5xl'],
    },
  },
  placeholder: {
    color: tokens.colors.neutral[80],
    fontSize: tokens.fontSizes.md,
    left: tokens.spacingX['5xl'],
    top: tokens.spacingX['6xl'],
    transition: `all ${tokens.motion.xs}`,
    selected: {
      fontSize: tokens.fontSizes.xs,
      top: tokens.spacingY['2xl'],
    },
    error: {
      color: tokens.colors.error[60],
    },
  },
  options: {
    boxShadow: tokens.shadow.sm,
    borderRadius: tokens.radius['4xl'],
    backgroundColor: tokens.colors.neutral[0],
    top: tokens.spacingTop['18xl'],
    item: {
      fontFamily: tokens.fontFamily.base,
      padding: `${tokens.spacingX['3xl']} ${tokens.spacingX['5xl']}`,
      fontSize: tokens.fontSizes.md,
      color: tokens.colors.neutral[80],
      selected: {
        color: tokens.colors.neutral[90],
      },
      hover: {
        backgroundColor: tokens.colors.neutral[10],
      },
      first: {
        borderRadius: tokens.spacingTop['3xl'],
      },
      last: {
        borderRadius: tokens.spacingBottom['3xl'],
      },
    },
    scrollbar: {
      backgroundColor: tokens.colors.neutral[20],
    },
  },
  selectedValue: {
    fontFamily: tokens.fontFamily.base,
    margin: `${tokens.spacingTop['4xl']} 0 0 0`,
    color: tokens.colors.neutral[90],
    fontSize: tokens.fontSizes.md,
    top: tokens.spacingY['8xl'],
  },
  disabled: {
    color: tokens.colors.neutral[40],
  },
  helperText: {
    padding: `${tokens.spacingTop.md} 0 0 0`,
    fontFamily: tokens.fontFamily.base,
    fontSize: tokens.fontSizes.xs,
    lineHeight: tokens.lineHeight.xs,
    fontWeight: tokens.fontWeight.xs,
    letterSpacing: tokens.letterSpacing.xs,
    color: tokens.colors.neutral[80],
    error: {
      color: tokens.colors.error[60],
    },
    success: {
      color: tokens.colors.primary[80],
    },
  },
})

export default dropdown
