import React, { useEffect, useState } from 'react'
import { createContext } from '@creditas-ui/utilities'

interface IContext {
  isDisabled?: boolean
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface Props {
  children: React.ReactNode
  isDisabled?: boolean
  isOpen?: boolean
  onChange?: (expanded: boolean) => void
}

const [AccordionItemRootProvider, useAccordionItemContext] =
  createContext<IContext>({})

const AccordionItemProvider = ({
  children,
  isDisabled,
  isOpen = false,
  onChange,
}: Props) => {
  const [isOpenState, setIsOpenState] = useState(isOpen)

  const value = {
    isDisabled,
    isOpen: isOpenState,
    setIsOpen: setIsOpenState,
  }

  useEffect(() => {
    onChange?.(isOpenState)
  }, [isOpenState])

  return (
    <AccordionItemRootProvider value={value}>
      {children}
    </AccordionItemRootProvider>
  )
}

export { AccordionItemProvider, useAccordionItemContext }
